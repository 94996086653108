import { Button, Form, Input, List } from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { register, resendVerification } from "src/pages/auth/login/api/auth";

export const Register = ({ orgInviteToken }) => {
  const [t] = useTranslation("dashboard");

  const [input, setInput] = useState({
    credentials: {
      email: "",
      username: "",
      password: "",
    },
    messages: {
      errors: {},
      status: "",
    },
    hasSentRegistration: false,
    hasVerified: false,
    countdown: 60,
    orgInviteToken: orgInviteToken,
  });

  const emailOnChange = ({ target: { value } }) => {
    setInput({ ...input, credentials: { ...input.credentials, email: value } });
  };

  const usernameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      credentials: { ...input.credentials, username: value },
    });
  };

  const passwordOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      credentials: { ...input.credentials, password: value },
    });
  };

  const registerOnClick = async () => {
    setInput({
      ...input,
      messages: { ...input.messages, status: "loading", errors: {} },
    });

    const { email, username, password } = input.credentials;
    const response = await register(
      email,
      username,
      password,
      input.orgInviteToken,
    );
    const errors = response?.error?.errors;

    if (errors !== null && errors !== undefined) {
      setInput({
        ...input,
        messages: {
          ...input.messages,
          status: "error",
          errors: errors,
        },
      });

      return;
    }

    setInput({
      ...input,
      messages: {
        ...input.messages,
        status: "success",
      },
      hasSentRegistration: true,
    });
  };

  const resendEmailOnClick = async () => {
    if (input.hasVerified) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      hasVerified: true,
      countdown: 60,
    }));

    const { email, password } = input.credentials;
    const response = await resendVerification(email, password);
    const errors = response?.error?.errors;

    if (errors !== null && errors !== undefined) {
      setInput({
        ...input,
        messages: {
          ...input.messages,
          status: "error",
          errors: errors,
        },
      });

      return;
    }

    setInput({
      ...input,
      messages: {
        ...input.messages,
        status: "success",
      },
    });

    const interval = setInterval(() => {
      setInput((prev) => {
        if (prev.countdown <= 1) {
          clearInterval(interval);
          return { ...prev, hasVerified: false, countdown: 0 };
        }
        return { ...prev, countdown: prev.countdown - 1 };
      });
    }, 1000);
  };

  console.log(input.messages.errors);

  return (
    <Form layout="vertical">
      <Form.Item>
        <Input
          placeholder={t("authentication.email")}
          value={input.credentials.email}
          onChange={emailOnChange}
          onPressEnter={registerOnClick}
          prefix={<MailOutlined />}
          status={input.messages.errors?.["email"] ? "error" : ""}
        />
      </Form.Item>
      <Form.Item>
        <Input
          placeholder={t("authentication.username")}
          value={input.credentials.username}
          onChange={usernameOnChange}
          onPressEnter={registerOnClick}
          prefix={<UserOutlined />}
          status={input.messages.errors?.["username"] ? "error" : ""}
        />
      </Form.Item>
      <Form.Item>
        <Input.Password
          placeholder={t("authentication.password")}
          value={input.credentials.password}
          onChange={passwordOnChange}
          onPressEnter={registerOnClick}
          prefix={<KeyOutlined />}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          minLength={8}
          status={input.messages.errors?.["password"] ? "error" : ""}
        />
      </Form.Item>
      {Object.keys(input.messages.errors || {}).length > 0 && (
        <Form.Item>
          <List
            dataSource={Object.values(input.messages.errors || {}).flat()}
            renderItem={(item) => (
              <List.Item color="#9e0002">{item.toString()}</List.Item>
            )}
            size="small"
          />
        </Form.Item>
      )}
      <Form.Item style={{ marginBottom: 0 }}>
        {input.hasSentRegistration ? (
          <Button
            onClick={resendEmailOnClick}
            type="primary"
            loading={input.hasVerified}
            style={{ width: "100%" }}
          >
            {t("authentication.resendEmail")}{" "}
            {input.countdown > 0 &&
              input.countdown !== 60 &&
              `(${input.countdown})`}
          </Button>
        ) : (
          <Button
            onClick={registerOnClick}
            type="primary"
            loading={input.messages.status === "loading"}
            style={{ width: "100%" }}
          >
            {t("authentication.register")}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
