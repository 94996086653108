import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { usePreferences } from "src/store/userPreferences";
import { fetchTeams } from "src/pages/profile/components/users/components/organization/api/team";
import { Select, Table } from "antd";
import {
  fetchOrgAdminUsers,
  persistOrgAdminUser,
} from "src/common/api/org/user";
import { fetchOrgAdminRoles } from "src/common/api/org/role";
import { error, success } from "src/store/notifications";
// import { fetchRoles } from "src/common/api/admin/role";

export const OrganizationUsers = () => {
  const [t] = useTranslation("dashboard");
  const { language } = usePreferences();

  const [input, setInput] = useState({
    users: [],
    teams: [],
    roles: [],
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        users: (await fetchOrgAdminUsers())?.users?.map((user) => ({
          key: user.id,
          value: user.id,
          name: user.name,
          email: user.email,
          teamId: user.teamId,
          roleIds: user.roleIds,
        })),
        teams: (await fetchTeams())?.teams?.map((team) => ({
          value: team.id,
          label: team.name,
        })),
        roles: (await fetchOrgAdminRoles())?.roles?.map((role) => ({
          value: role.id,
          label: role.name,
        })),
      });
    };

    init();
  }, []);

  const teamOnChange = async (userId, teamId) => {
    try {
      const response = await persistOrgAdminUser(
        userId,
        teamId,
        input.users.find((user) => user.key === userId).roleIds,
      );

      if (response?.errors) {
        error(t("organization.teamPersistedName"));
        return;
      }

      setInput({
        ...input,
        users: input.users.map((user) =>
          user.key === userId ? { ...user, teamId: teamId } : user,
        ),
      });
      success(t("organization.teamPersistedName"));
    } catch (e) {
      console.error("Persist", e);
      error(t("organization.teamPersistedName"));
    }
  };

  const roleOnChange = async (userId, roleIds) => {
    try {
      const response = await persistOrgAdminUser(
        userId,
        input.users.find((user) => user.key === userId).teamId,
        roleIds,
      );

      if (response?.errors) {
        error(t("account.rolePersisted"));
        return;
      }

      setInput({
        ...input,
        users: input.users.map((user) =>
          user.key === userId ? { ...user, roleIds: roleIds } : user,
        ),
      });
      success(t("account.rolePersisted"));
    } catch (e) {
      console.error("Persist", e);
      error(t("account.rolePersisted"));
    }
  };

  return (
    <Table
      columns={[
        {
          key: "name",
          dataIndex: "name",
          title: t("account.user"),
          width: "25%",
        },
        {
          key: "email",
          dataIndex: "email",
          title: t("authentication.email"),
          width: "25%",
        },
        {
          key: "teamId",
          dataIndex: "teamId",
          title: t("organization.team"),
          width: "25%",
          render: (value, { key }) => (
            <>
              <Select
                value={input.users?.find((user) => user.key === key)?.teamId}
                options={input.teams}
                onChange={(inputValue) => teamOnChange(key, inputValue)}
                placeholder={t("organization.team")}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
              />
            </>
          ),
        },
        {
          key: "roleIds",
          dataIndex: "roleIds",
          title: t("account.roles"),
          width: "25%",
          render: (value, { key }) => (
            <>
              <Select
                value={input.users?.find((user) => user.key === key)?.roleIds}
                options={input.roles}
                onChange={(inputValue) => roleOnChange(key, inputValue)}
                placeholder={t("account.roles")}
                showSearch
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
              />
            </>
          ),
        },
      ]}
      dataSource={input.users}
      size="small"
      scroll={{ x: "max-content", y: 400 }}
      pagination={{ pageSize: 10 }}
      className={"table-striped-rows"}
    />
  );
};
