import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Select, Table } from "antd";
import {
  fetchAdminRoles,
  persistAdminUserRole,
} from "src/common/api/admin/role";
import { error, success } from "src/store/notifications";
import { fetchAdminUsers } from "src/common/api/admin/user";

export const AdminRole = () => {
  const [t] = useTranslation("dashboard");
  const [input, setInput] = useState({
    user: null,
    users: [],
    roles: [],
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        users: (await fetchAdminUsers())?.users?.map((user) => ({
          key: user.id,
          value: user.id,
          name: user.name,
          email: user.email,
          roleIds: user.roleIds,
        })),
        roles: (await fetchAdminRoles())?.roles?.map((role) => ({
          key: role.id,
          value: role.id,
          label: role.name,
        })),
      });
    };

    init();
  }, []);

  const roleOnChange = async (userId, selectedRoleIds) => {
    try {
      const { errors, roleIds } = await persistAdminUserRole(
        userId,
        selectedRoleIds,
      );

      if (errors) {
        error(t("account.rolePersisted"));
        return;
      }

      setInput({
        ...input,
        users: input.users.map((user) =>
          user.key === userId ? { ...user, roleIds: roleIds } : user,
        ),
      });
      success(t("account.rolePersisted"));
    } catch (e) {
      console.error("Persist", e);
      error(t("account.rolePersisted"));
    }
  };

  return (
    <Table
      columns={[
        {
          key: "name",
          dataIndex: "name",
          title: t("account.user"),
          width: "25%",
        },
        {
          key: "email",
          dataIndex: "email",
          title: t("authentication.email"),
          width: "25%",
        },
        {
          key: "roleIds",
          dataIndex: "roleIds",
          title: t("account.roles"),
          width: "50%",
          render: (value, { key }) => (
            <>
              <Select
                value={input.users?.find((user) => user.key === key)?.roleIds}
                options={input.roles}
                onChange={(inputValue) => roleOnChange(key, inputValue)}
                placeholder={t("account.roles")}
                showSearch
                allowClear
                mode="multiple"
                maxTagCount="responsive"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
              />
            </>
          ),
        },
      ]}
      dataSource={input.users}
      size="small"
      scroll={{ x: "max-content", y: 400 }}
      pagination={{ pageSize: 10 }}
      className={"table-striped-rows"}
    />
  );
};
