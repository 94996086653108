import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
} from "antd";
import { MailOutlined, SaveOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { error, success } from "src/store/notifications";
import {
  fetchOrganization,
  persistOrganization,
} from "src/pages/profile/components/users/api/organization";
import { inviteUser } from "src/pages/profile/components/users/api/userAccess";
import { countries } from "src/components/country";

export const OrganizationAccount = () => {
  const [t] = useTranslation("dashboard");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    organization: {
      code: null,
      name: null,
      country: null,
      valid: false,
    },
    countries: countries.map((item) => ({
      value: item.code,
      label: `${item.flag} ${item.name} ${
        item.name === item.country ? "" : `(${item.country})`
      }`,
    })),
    invite: {
      email: null,
    },
  });

  useEffect(() => {
    const init = async () => {
      const organization = (await fetchOrganization()) || {};

      setInput({
        ...input,
        organization: {
          ...input.organization,
          code: organization?.code,
          name: organization?.name,
          country: organization?.country_code,
          valid: !organization?.error,
        },
      });
    };

    init();
  }, []);

  const organizationNameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      organization: { ...input.organization, name: value },
    });
  };

  const organizationCodeOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      organization: { ...input.organization, code: value },
    });
  };

  const countryOnSelect = (value) => {
    setInput({
      ...input,
      organization: { ...input.organization, country: value },
    });
  };

  const organizationPreferencesSaveOnClick = async () => {
    try {
      setInput({
        ...input,
        organization: { ...input.organization, valid: false },
      });

      const response = await persistOrganization(
        input.organization.code?.trim(),
        input.organization.name?.trim(),
        input.organization.country?.trim(),
      );

      if (response?.errors) {
        error(t("organization.organizationNamePersisted"));
        return;
      }

      success(t("organization.organizationNamePersisted"));
      setInput({
        ...input,
        organization: {
          ...input.organization,
          code: response.code,
          name: response.name,
          country: response.country_code,
          valid: true,
        },
      });
    } catch (e) {
      error(t("organization.organizationNamePersisted"));
      console.log("Persist", e.message);
    }
  };

  const inviteEmailOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      invite: { ...input.invite, email: value },
    });
  };

  const inviteEmailOnClick = async () => {
    try {
      await inviteUser(input.invite.email);
      success(t("organization.invitationSent"));
    } catch (e) {
      error(t("organization.invitationSent"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("organization.organizationName")}>
        <Input
          value={input.organization.name}
          onChange={organizationNameOnChange}
        />
      </Form.Item>
      <Form.Item label={t("organization.organizationCode")}>
        <Input
          value={input.organization.code}
          onChange={organizationCodeOnChange}
        />
      </Form.Item>
      <Form.Item label={t("profile.country")}>
        <Select
          value={input.organization.country}
          onChange={countryOnSelect}
          options={input.countries}
          showSearch
          optionFilterProp="label"
          virtual
        />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          <Button
            onClick={organizationPreferencesSaveOnClick}
            type="primary"
            icon={<SaveOutlined />}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
      <Divider />
      <Form.Item label={t("organization.inviteUsersToOrganization")}>
        <Space.Compact style={{ width: "100%" }}>
          <Input
            value={input.invite.email}
            onChange={inviteEmailOnChange}
            onPressEnter={inviteEmailOnClick}
            disabled={!input.organization.valid}
          />
          <Button
            onClick={inviteEmailOnClick}
            disabled={!input.organization.valid}
            type="primary"
            icon={<MailOutlined />}
          />
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
