import { deleteAPI, getAPI, postAPI } from "src/common/api/api";

const PATH = "catman/product";

export const fetchProducts = async (lang) => getAPI(PATH);

export const fetchProduct = async (id) => getAPI(`${PATH}/${id}`);

export const persistProduct = async (body) => postAPI(PATH, body);

export const deleteProduct = async (id) => deleteAPI(`${PATH}/${id}`);
