import {
  Button,
  Form,
  Input,
  InputNumber,
  Segmented,
  Select,
  Space,
  Switch,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { fetchCategories } from "src/pages/catman/components/products/components/product/api/category";
import { useParams } from "react-router-dom";
import { fetchTrademarks } from "src/pages/catman/components/products/components/product/api/trademark";
import { fetchProductGroupParameters } from "src/pages/catman/components/products/components/product/api/groupParameter";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { error, success } from "src/store/notifications";
import {
  fetchProduct,
  fetchProducts,
  persistProduct,
} from "src/pages/catman/components/products/components/product/api/product";

export const Product = () => {
  const TEXT = "TEXT";
  const NUMBER = "NUMBER";
  const LOGICAL = "LOGICAL";

  const { t } = useTranslation("catman");
  const { lang } = useParams();

  const [input, setInput] = useState({
    status: "new",
    trademarks: [],
    categories: [],
    groupParameters: [],
    product: {
      productId: null,
      ean: "",
      mpn: "",
      trademarkId: null,
      categoryId: null,
      groupParameters: null,
    },
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        trademarks: (await fetchTrademarks()) || [],
        categories: (await fetchCategories()) || [],
        products: (await fetchProducts())?.map((product) => ({
          value: product.productId,
          label: `${product.ean} (${product.mpn})`,
          brandId: product.brandId,
          categoryId: product.categoryId,
        })),
      });
    };

    init();
  }, []);

  const traverseTree = (data, source) => {
    return data.map((item) => ({
      ...item,
      disabled: item.type === source,
      children: item?.children ? traverseTree(item?.children, source) : [],
    }));
  };

  const findCategoryById = (categories, categoryId) => {
    for (const category of categories) {
      if (category.type === "CATEGORY") {
        const currentCategoryId = category.value.split("|")[1];
        if (currentCategoryId === categoryId.toString()) {
          return category.value;
        }
      }

      if (category.children) {
        const result = findCategoryById(category.children, categoryId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const productStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
    });
  };

  const existingProductOnChange = async (value) => {
    try {
      const currentProduct = input.products.find(
        (product) => product.value === value,
      );

      const groupParameters = (
        await fetchProductGroupParameters(currentProduct.categoryId)
      )?.groupParameters;
      const product = (await fetchProduct(value))?.product;

      setInput({
        ...input,
        groupParameters: groupParameters?.map((groupParameter) => ({
          value: groupParameter.id,
          title: groupParameter.name,
          parameters: groupParameter?.parameters?.map((parameter) => ({
            value: parameter.id,
            title: parameter.name,
            type: parameter.type,
            minValue: parameter.minValue,
            maxValue: parameter.maxValue,
            decimalPlaceValue: parameter.decimalPlaceValue,
            options: parameter?.options?.map((option) => ({
              value: option.id,
              label: option.name,
            })),
          })),
        })),
        product: {
          ...input.product,
          productId: value,
          trademarkId: `${product.brandId}|${product.trademarkId}`,
          ean: product.ean,
          mpn: product.mpn,
          categoryId: findCategoryById(input.categories, product.categoryId),
          groupParameters: product.groupParameters?.map((groupParameter) => ({
            value: groupParameter.id,
            parameters: groupParameter?.parameters?.map((parameter) => ({
              value: parameter.id,
              min: parameter.min,
              max: parameter.max,
              selected: parameter.selected,
              options: parameter?.options,
            })),
          })),
        },
      });
    } catch (e) {
      console.log("Persist", e.message);
    }
  };

  const trademarkOnChange = (value) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        trademarkId: value,
      },
    });
  };

  const eanOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        ean: value,
      },
    });
  };

  const mpnOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        mpn: value,
      },
    });
  };

  const categoryOnChange = async (value) => {
    const categoryId = value.split("|")[1];
    const { errors, groupParameters } =
      await fetchProductGroupParameters(categoryId);

    if (errors) {
      console.log(errors);
      return;
    }

    setInput({
      ...input,
      product: {
        ...input.product,
        categoryId: value,
        groupParameters: groupParameters?.map((groupParameter) => ({
          id: groupParameter.id,
          parameters: groupParameter?.parameters?.map((parameter) => ({
            id: parameter.id,
            type: parameter.type,
            min: null,
            max: null,
            selected: null,
            options: null,
          })),
        })),
      },
      groupParameters: groupParameters?.map((groupParameter) => ({
        value: groupParameter.id,
        title: groupParameter.name,
        parameters: groupParameter?.parameters?.map((parameter) => ({
          value: parameter.id,
          title: parameter.name,
          type: parameter.type,
          minValue: parameter.minValue,
          maxValue: parameter.maxValue,
          decimalPlaceValue: parameter.decimalPlaceValue,
          options: parameter?.options?.map((option) => ({
            value: option.id,
            label: option.name,
          })),
        })),
      })),
    });
  };

  const parameterTextOnChange = (parameterId, value) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        groupParameters: input.product.groupParameters?.map(
          (groupParameter) => ({
            ...groupParameter,
            parameters: groupParameter.parameters?.map((parameter) =>
              parameter.value === parameterId
                ? { ...parameter, options: value }
                : parameter,
            ),
          }),
        ),
      },
    });
  };

  const parameterMinNumberOnChange = (parameterId, value) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        groupParameters: input.product.groupParameters?.map(
          (groupParameter) => ({
            ...groupParameter,
            parameters: groupParameter.parameters?.map((parameter) =>
              parameter.value === parameterId
                ? { ...parameter, min: value }
                : parameter,
            ),
          }),
        ),
      },
    });
  };

  const parameterMaxNumberOnChange = (parameterId, value) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        groupParameters: input.product.groupParameters?.map(
          (groupParameter) => ({
            ...groupParameter,
            parameters: groupParameter.parameters?.map((parameter) =>
              parameter.value === parameterId
                ? { ...parameter, max: value }
                : parameter,
            ),
          }),
        ),
      },
    });
  };

  const parameterLogicalOnChange = (parameterId, value) => {
    setInput({
      ...input,
      product: {
        ...input.product,
        groupParameters: input.product.groupParameters?.map(
          (groupParameter) => ({
            ...groupParameter,
            parameters: groupParameter.parameters?.map((parameter) =>
              parameter.value === parameterId
                ? { ...parameter, selected: value }
                : parameter,
            ),
          }),
        ),
      },
    });
  };

  const deleteProductOnClick = async () => {};

  const saveProductOnClick = async () => {
    try {
      const product = input.product;
      const request = {
        productId: product.productId?.toString(),
        ean: product.ean,
        mpn: product.mpn,
        brandId: product.trademarkId.split("|")[0],
        trademarkId: product.trademarkId.split("|")[1],
        categoryId: product.categoryId.split("|")[1],
        groupParameters: product.groupParameters?.map((groupParameter) => ({
          id: groupParameter.value,
          parameters: groupParameter?.parameters?.map((parameter) => ({
            id: parameter.value,
            min: parameter.min,
            max: parameter.max,
            options: parameter.options,
            selected: parameter.selected,
          })),
        })),
      };

      const response = await persistProduct(request);

      if (response?.errors) {
        error(t("product.persist"));
        return;
      }

      success(t("product.persist"));
    } catch (e) {
      console.log(e);
      error(t("product.persist"));
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={productStatusOnChange}
          />
          {input.status === "existing" && (
            <Select
              placeholder={t("product.existingProduct")}
              value={input.product.productId}
              onChange={existingProductOnChange}
              options={input.products}
              showSearch
              optionFilterProp="label"
              virtual={true}
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("trademark.existingTrademark")}>
        <TreeSelect
          placeholder={t("trademark.existingTrademark")}
          value={input.product?.trademarkId}
          onChange={trademarkOnChange}
          treeData={input.trademarks.map((brand) => ({
            value: brand.value,
            title: brand.title,
            disabled: true,
            children: brand.trademarks?.map((trademark) => ({
              value: `${brand.value}|${trademark.value}`,
              title: trademark.title,
              disabled: false,
            })),
          }))}
          showSearch
          allowClear
          treeDefaultExpandAll
        />
      </Form.Item>
      <Form.Item label={t("product.ean")}>
        <Input
          value={input.product?.ean}
          onChange={eanOnChange}
          placeholder={t("product.ean")}
          allowClear
        />
      </Form.Item>
      <Form.Item label={t("product.mpn")}>
        <Input
          value={input.product?.mpn}
          onChange={mpnOnChange}
          placeholder={t("product.mpn")}
          allowClear
        />
      </Form.Item>
      <Form.Item label={t("category.categoryName")}>
        <TreeSelect
          treeData={traverseTree(input.categories, "NAVIGATION")}
          value={input.product?.categoryId}
          onChange={categoryOnChange}
          filterTreeNode={(input, node) =>
            node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          placeholder={t("category.categoryName")}
          showSearch
          allowClear
          treeDefaultExpandAll
          style={{
            width: "100%",
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
        />
      </Form.Item>
      {input.groupParameters.map((groupParameter) => (
        <Form.Item
          label={`${t("groupParameter.groupParameters")} (${
            groupParameter.title
          })`}
          key={groupParameter.value}
        >
          {groupParameter.parameters.map((parameter) => (
            <Form.Item
              key={parameter.value}
              label={`${t("parameterOption.parameterOptions")} (${
                parameter.title
              })`}
            >
              {parameter.type === "TEXT" && (
                <Select
                  value={
                    input.product.groupParameters
                      ?.find((param) => param.value === groupParameter.value)
                      ?.parameters?.find(
                        (param) => param.value === parameter.value,
                      )?.options
                  }
                  onChange={(value) =>
                    parameterTextOnChange(parameter.value, value)
                  }
                  options={parameter.options}
                  showSearch
                  optionFilterProp="label"
                  virtual={true}
                />
              )}
              {parameter.type === "NUMBER" && (
                <Space.Compact>
                  <InputNumber
                    value={
                      input.product.groupParameters
                        ?.find((param) => param.value === groupParameter.value)
                        ?.parameters?.find(
                          (param) => param.value === parameter.value,
                        )?.min
                    }
                    onChange={(value) => {
                      parameterMinNumberOnChange(parameter.value, value);
                    }}
                    min={parameter?.minValue}
                    max={parameter?.maxValue}
                    step={parseFloat(
                      parameter?.decimalPlaceValue === "0"
                        ? "1"
                        : `0.${parameter?.decimalPlaceValue}`,
                    )}
                  />
                  <InputNumber
                    value={
                      input.product.groupParameters
                        ?.find((param) => param.value === groupParameter.value)
                        ?.parameters?.find(
                          (param) => param.value === parameter.value,
                        )?.max
                    }
                    onChange={(value) => {
                      parameterMaxNumberOnChange(parameter.value, value);
                    }}
                    min={parameter?.minValue}
                    max={parameter?.maxValue}
                    step={parseFloat(
                      parameter?.decimalPlaceValue === "0"
                        ? "1"
                        : `0.${parameter?.decimalPlaceValue}`,
                    )}
                  />
                </Space.Compact>
              )}
              {parameter.type === "LOGICAL" && (
                <Switch
                  value={
                    input.product.groupParameters
                      ?.find((param) => param.value === groupParameter.value)
                      ?.parameters?.find(
                        (param) => param.value === parameter.value,
                      )?.selected
                  }
                  onChange={(value) => {
                    parameterLogicalOnChange(parameter.value, value);
                  }}
                />
              )}
            </Form.Item>
          ))}
        </Form.Item>
      ))}
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.status === "existing" && input.productId !== null && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteProductOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveProductOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
