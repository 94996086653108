import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout, { Content } from "antd/lib/layout/layout";
import {
  Button,
  Cascader,
  Checkbox,
  Collapse,
  Form,
  InputNumber,
  Space,
  Table,
} from "antd";
import {
  DeleteOutlined,
  FilterOutlined,
  FunnelPlotOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Card } from "antd/lib";
import "./Home.css";
import Sider from "antd/lib/layout/Sider";
import { fetchCategories } from "src/pages/home/api/category";
import { fetchFilters } from "src/pages/home/api/filter";
import { fetchProducts } from "src/pages/home/api/product";
import { usePreferences } from "src/store/userPreferences";

export default function Home() {
  const PRODUCTS = "products";
  const CHARACTERISTICS = "characteristics";
  const ADDITIONAL_INFO = "additionalInfo";

  const [t] = useTranslation("dashboard");
  const { language } = usePreferences();

  const [input, setInput] = useState({
    filtersCollapsed: true,
    columnsCollapsed: true,
    activeTab: PRODUCTS,
    categories: [],
    categoryId: null,
    table: {
      loading: false,
      columns: [],
      columnValues: [],
      selectedFilters: {},
      rawFilters: [],
      filters: [],
      filteredColumns: {},
      data: [],
      filteredData: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
    },
  });

  useEffect(() => {
    const init = async () => {
      let filters = [];
      let products = [];

      if (input.categoryId !== null) {
        const categoryId = input.categoryId?.at(-1)?.split("|")[1];
        filters = (await fetchFilters(language, categoryId)?.items) || [];
        products = await fetchProducts(categoryId, language);
      }

      const categories = (await fetchCategories(language)) || [];

      const data = tableData(products, filters);

      setInput({
        ...input,
        categories: Array.isArray(categories) ? categories : [],
        table: {
          ...input.table,
          loading: false,
          columnValues: columnParameterValues(filters),
          columns: products.length > 0 ? columns(filters) : [],
          data: data,
          filteredData: data,
          filters: filters,
          rawFilters: filters,
        },
      });
    };

    init();
  }, [language]);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     setInput({
  //       filtersCollapsed: true,
  //       columnsCollapsed: true,
  //       activeTab: PRODUCTS,
  //       categories: [],
  //       categoryId: null,
  //       table: {
  //         loading: false,
  //         columns: [],
  //         columnValues: [],
  //         selectedFilters: {},
  //         rawFilters: [],
  //         filters: [],
  //         filteredColumns: {},
  //         data: [],
  //         filteredData: [],
  //         pagination: {
  //           current: 1,
  //           pageSize: 10,
  //         },
  //       },
  //     });
  //   }
  // }, [isAuthenticated]);

  const calculateFilterCounts = (products, filters, activeFilters = {}) => {
    // Clone the filters to avoid mutating the original
    const clonedFilters = JSON.parse(JSON.stringify(filters));

    clonedFilters?.forEach((groupParam) => {
      groupParam?.parameters?.forEach((param) => {
        const compositeKey = `${groupParam.id}|${param.id}`;

        // Create temporary filters without current parameter's selections
        const tempFilters = { ...activeFilters };
        delete tempFilters[compositeKey];

        if (param.type === "TEXT") {
          param.options?.forEach((option) => {
            const currentFilters = {
              ...tempFilters,
              [compositeKey]: [option.id],
            };
            option.count = countMatchingProducts(
              products,
              currentFilters,
              clonedFilters,
            );
          });
        } else if (param.type === "NUMBER") {
          // Use active range or default min/max
          const range = activeFilters[compositeKey] || {
            min: parseFloat(param.minValue || 0),
            max: parseFloat(param.maxValue || 0),
          };
          const currentFilters = {
            ...tempFilters,
            [compositeKey]: range,
          };
          param.count = countMatchingProducts(
            products,
            currentFilters,
            clonedFilters,
          );
        }
      });
    });

    return clonedFilters || [];
  };

  const countMatchingProducts = (products, filters, filterStructure) => {
    return (
      products?.filter((product) => {
        return Object.entries(filters || {}).every(([key, filterValue]) => {
          const productValues = product?._parameters?.[key];
          if (!productValues) return false;

          // Find parameter type from filter structure
          const [itemId, paramId] = key?.split("|");
          const param = filterStructure
            ?.find((groupParam) => groupParam.id === itemId)
            ?.parameters?.find((param) => param.id === paramId);

          if (param?.type === "TEXT") {
            return productValues?.some((v) => filterValue.includes(v));
          } else if (param?.type === "NUMBER") {
            const numValue = parseFloat(productValues[0] || 0);
            return (
              numValue >= filterValue.minValue &&
              numValue <= filterValue.maxValue
            );
          }
          return false;
        });
      })?.length || 0
    );
  };

  const filterProducts = (products, filters, activeFilters) => {
    return products.filter((product) => {
      return Object.entries(activeFilters || {}).every(([key, filterValue]) => {
        const productValues = product?._parameters[key];
        if (!productValues) {
          return false;
        }

        // Extract parameter type from filter structure
        const [itemId, paramId] = key?.split("|");
        const param = filters
          ?.find((groupParam) => groupParam.id === itemId)
          ?.parameters?.find((param) => param.id === paramId);

        if (!param) {
          return false;
        }

        if (param.type === "TEXT") {
          // Filter by selected options
          return productValues?.some((v) => filterValue?.includes(v));
        } else if (param.type === "NUMBER") {
          // Filter by numeric range
          const numValue = parseFloat(productValues[0]);
          return (
            numValue >= filterValue.minValue && numValue <= filterValue.maxValue
          );
        }

        return false;
      });
    });
  };

  const columns = (filters) => {
    const chars = 15;

    return [
      {
        key: "ean",
        dataIndex: "ean",
        title: t("product.ean"),
        width: t("product.ean").length * chars,
      },
      {
        key: "mpn",
        dataIndex: "mpn",
        title: t("product.mpn"),
        width: t("product.mpn").length * chars,
      },
      {
        key: "brand",
        dataIndex: "brand",
        title: t("brand.brand"),
        width: t("brand.brand").length * chars,
      },
      {
        key: "trademark",
        dataIndex: "trademark",
        title: t("trademark.trademark"),
        width: t("trademark.trademark").length * chars,
      },
      ...filters
        ?.sort((a, b) => a.orderIndex - b.orderIndex)
        ?.flatMap((groupParam) =>
          groupParam?.parameters
            ?.sort((a, b) => a.orderIndex - b.orderIndex)
            .map((param) => ({
              key: `${groupParam.id}|${param.id}`,
              dataIndex: `${groupParam.id}|${param.id}`,
              title: param.title,
              width: param.title.length * chars,
            })),
        ),
    ];
  };

  const tableData = (products, filters) => {
    return products.map((product) => {
      return {
        key: product.id,
        ean: product.ean,
        mpn: product.mpn,
        brand: product.brand,
        trademark: product.trademark,
        ...tableDataParameterValues(
          product.parameters,
          input.table.columnValues,
          filters,
        ),
      };
    });
  };

  const columnParameterValues = (filters) =>
    filters?.reduce((acc, groupParam) => {
      const parameters = groupParam?.parameters || [];
      for (const param of parameters) {
        const options = param?.options || [];
        acc[`${groupParam.id}|${param.id}`] = {};
        for (const option of options) {
          acc[`${groupParam.id}|${param.id}`][option.id] = option.title;
        }
      }
      return acc;
    }, {}) || {};

  const tableDataParameterValues = (parameters, columnValues, filters) => {
    const parameterValues =
      Object.keys(columnValues || {})?.length > 0
        ? columnValues
        : columnParameterValues(filters);

    return Object.keys(parameters || {}).reduce((acc, key) => {
      acc[key] = (parameters?.[key] || [])
        .map((option) => parameterValues[key]?.[option])
        .join(", ");
      acc["_parameters"] = parameters;
      return acc;
    }, {});
  };

  const onTabChange = (key) => {
    setInput({
      ...input,
      filtersCollapsed: key === PRODUCTS ? input.filtersCollapsed : true,
      activeTab: key,
    });
  };

  const collapseFiltersSidebar = () => {
    setInput({
      ...input,
      filtersCollapsed: !input.filtersCollapsed,
    });
  };

  const collapseColumnsSidebar = () => {
    setInput({
      ...input,
      columnsCollapsed: !input.columnsCollapsed,
    });
  };

  const categoryOnChange = async (value) => {
    if (!value || value?.length === 0) {
      setInput({
        categoryId: null,
        table: {
          loading: false,
          columns: [],
          data: [],
          filters: [],
        },
      });
      return;
    }

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        loading: true,
      },
    });

    const categoryId = value?.at(-1)?.split("|")[1];
    const filters = (await fetchFilters(language, categoryId))?.items || [];
    const products = await fetchProducts(categoryId, language);

    const data = tableData(products, filters);

    setInput({
      ...input,
      categoryId: value,
      table: {
        ...input.table,
        columnValues: columnParameterValues(filters),
        columns: products.length > 0 ? columns(filters) : [],
        data: data,
        filteredData: data,
        rawFilters: filters,
        filters: calculateFilterCounts(data, filters, {}),
        selectedFilters: {},
        loading: false,
      },
    });
  };

  const filterOptionOnChange = (groupParameterId, parameterId, value) => {
    const selectedFilters = Object.fromEntries(
      Object.entries({
        ...input.table.selectedFilters,
        [`${groupParameterId}|${parameterId}`]: value,
      }).filter(([key, value]) => Array.isArray(value) && value.length > 0),
    );

    const products = filterProducts(
      input.table.data,
      input.table.filters,
      selectedFilters,
    );

    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: selectedFilters,
        filteredData: products,
        filters: calculateFilterCounts(
          products,
          input.table.filters,
          selectedFilters,
        ),
      },
    });
  };

  const clearFiltersOnClick = () => {
    setInput({
      ...input,
      table: {
        ...input.table,
        selectedFilters: {},
        filters: calculateFilterCounts(
          input.table.data,
          input.table.rawFilters,
          {},
        ),
        filteredData: input.table.data,
      },
    });
  };

  const columnGroupOptionFilterOnChange = (isChecked, groupParameter) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        filteredColumns: {
          ...input.table.filteredColumns,
          [groupParameter?.id]: isChecked
            ? groupParameter?.parameters?.map((parameter) => parameter.id)
            : [],
        },
      },
    });
  };

  const columnOptionFilterOnChange = (groupParameterId, parameterIds) => {
    setInput({
      ...input,
      table: {
        ...input.table,
        filteredColumns: {
          ...input.table.filteredColumns,
          [groupParameterId]: parameterIds,
        },
      },
    });
  };

  return (
    <>
      <Content style={{ overflow: "auto" }}>
        <Card
          bordered={false}
          style={{
            padding: "0 1em",
          }}
        >
          <Cascader
            expandTrigger="hover"
            value={input.categoryId}
            options={input.categories}
            onChange={categoryOnChange}
            placeholder={t("category.category")}
            showSearch={{
              filter: (input, data) =>
                data?.some(
                  (option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >
                    -1,
                ),
            }}
            style={{ width: "100%" }}
          />
        </Card>
        {input.categoryId && (
          <Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.filtersCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.filtersCollapsed ? "0" : "2.4em 0 0 2.5em",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.filtersCollapsed && (
                <>
                  <Space
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      padding: ".3em 0",
                    }}
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      type="link"
                      iconPosition="end"
                      onClick={clearFiltersOnClick}
                    >
                      {t("filter.reset")}
                    </Button>
                  </Space>
                  <Collapse
                    items={input.table.filters?.map((groupParameter) => ({
                      key: groupParameter?.id,
                      label: <b>{groupParameter?.title}</b>,
                      children: (
                        <Form layout="vertical">
                          {groupParameter?.parameters?.map((parameter) => (
                            <Form.Item
                              key={`${groupParameter.id}|${parameter?.id}`}
                              label={
                                parameter?.type === "TEXT"
                                  ? parameter?.title
                                  : `${parameter?.title} (${
                                      parameter?.count || 0
                                    })`
                              }
                            >
                              {parameter?.type === "TEXT" && (
                                <Checkbox.Group
                                  value={
                                    input.table.selectedFilters[
                                      `${groupParameter.id}|${parameter.id}`
                                    ]
                                  }
                                  options={parameter?.options?.map(
                                    (option) => ({
                                      value: option.id,
                                      label: `${option.title} (${
                                        option.count || 0
                                      })`,
                                      disabled:
                                        option.count === 0 &&
                                        !input.table.selectedFilters[
                                          `${groupParameter.id}|${parameter.id}`
                                        ]?.includes(option.id),
                                    }),
                                  )}
                                  onChange={(value) =>
                                    filterOptionOnChange(
                                      groupParameter.id,
                                      parameter.id,
                                      value,
                                    )
                                  }
                                  style={{ flexDirection: "column" }}
                                />
                              )}
                              {parameter?.type === "LOGICAL" && (
                                <Checkbox
                                  checked={
                                    input.table.selectedFilters[
                                      `${groupParameter.id}|${parameter.id}`
                                    ]
                                  }
                                  onChange={({ taget: { checked } }) =>
                                    filterOptionOnChange(
                                      groupParameter.id,
                                      parameter.id,
                                      checked,
                                    )
                                  }
                                  disabled={
                                    parameter.count === 0 &&
                                    !input.table.selectedFilters[
                                      `${groupParameter.id}|${parameter.id}`
                                    ]
                                  }
                                >
                                  {parameter?.title} ({parameter?.count})
                                </Checkbox>
                              )}
                              {parameter?.type === "NUMBER" && (
                                <Space.Compact style={{ width: "100%" }}>
                                  <InputNumber
                                    value={
                                      input.table.selectedFilters[
                                        `${groupParameter.id}|${parameter.id}`
                                      ]?.minValue || parameter?.minValue
                                    }
                                    onChange={(value) =>
                                      filterOptionOnChange(
                                        groupParameter.id,
                                        parameter.id,
                                        {
                                          minValue: parseFloat(value || 0),
                                          maxValue: parseFloat(
                                            parameter?.maxValue || 0,
                                          ),
                                        },
                                      )
                                    }
                                    min={parameter?.minValue}
                                    max={parameter?.maxValue}
                                    disabled={
                                      parameter?.count === 0 &&
                                      !input.table.selectedFilters[
                                        `${groupParameter.id}|${parameter.id}`
                                      ]?.minValue
                                    }
                                    style={{ width: "50%" }}
                                  />
                                  <InputNumber
                                    value={
                                      input.table.selectedFilters[
                                        `${groupParameter.id}|${parameter.id}`
                                      ]?.maxValue || parameter?.maxValue
                                    }
                                    onChange={(value) =>
                                      filterOptionOnChange(
                                        groupParameter.id,
                                        parameter.id,
                                        {
                                          minValue: parseFloat(
                                            parameter?.minValue || 0,
                                          ),
                                          maxValue: parseFloat(value || 0),
                                        },
                                      )
                                    }
                                    min={parameter?.minValue}
                                    max={parameter?.maxValue}
                                    disabled={
                                      parameter?.count === 0 &&
                                      !input.table.selectedFilters[
                                        `${groupParameter.id}|${parameter.id}`
                                      ]?.maxValue
                                    }
                                    style={{ width: "50%" }}
                                  />
                                </Space.Compact>
                              )}
                            </Form.Item>
                          ))}
                        </Form>
                      ),
                    }))}
                    accordion
                  />
                </>
              )}
            </Sider>
            <Layout style={{ background: "#fff" }}>
              <Card
                bordered={false}
                size="small"
                tabList={[
                  {
                    key: PRODUCTS,
                    tab: t("dashboard.products"),
                  },
                  {
                    key: CHARACTERISTICS,
                    tab: t("dashboard.characteristics"),
                  },
                  {
                    key: ADDITIONAL_INFO,
                    tab: t("dashboard.additionalInfo"),
                  },
                ]}
                activeTabKey={input.activeTab}
                onTabChange={onTabChange}
                style={{
                  padding: "0 2em",
                }}
              >
                {input.activeTab === PRODUCTS && (
                  <>
                    <Space
                      style={{
                        paddingBottom: ".7em",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        onClick={collapseFiltersSidebar}
                        icon={
                          input.filtersCollapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                      <Button
                        onClick={collapseColumnsSidebar}
                        icon={
                          input.columnsCollapsed ? (
                            <FilterOutlined />
                          ) : (
                            <FunnelPlotOutlined />
                          )
                        }
                        style={{
                          width: 45,
                          height: 32,
                        }}
                      />
                    </Space>
                    <Table
                      size="middle"
                      columns={input.table.columns?.map((col) => ({
                        ...col,
                        hidden:
                          !["ean", "mpn", "brand", "trademark"].includes(
                            col.key,
                          ) &&
                          !Object.entries(input.table.filteredColumns || {})
                            ?.flatMap(([groupParam, params]) =>
                              params?.map((param) => `${groupParam}|${param}`),
                            )
                            ?.includes(col.key),
                      }))}
                      dataSource={input.table.filteredData}
                      scroll={{ x: "max-content", y: 400 }}
                      pagination={{ pageSize: 10 }}
                      loading={input.table.loading}
                      className={"table-striped-rows"}
                    />
                  </>
                )}
              </Card>
            </Layout>
            <Sider
              collapsible
              trigger={null}
              breakpoint="lg"
              collapsedWidth={0}
              collapsed={input.columnsCollapsed}
              width={300}
              style={{
                background: "#fff",
                padding: input.columnsCollapsed ? "0" : "2.4em 2.5em 0 0",
                overflow: "auto",
                height: "calc(100vh - 11em)",
                scrollbarWidth: "thin",
                scrollbarGutter: "stable",
              }}
            >
              {!input.columnsCollapsed && (
                <>
                  <Form layout="vertical">
                    {input.table.filters?.map((groupParameter) => (
                      <div key={groupParameter?.id}>
                        <Form.Item>
                          <Checkbox
                            indeterminate={
                              input?.table.filteredColumns?.[groupParameter?.id]
                                ?.length > 0 &&
                              input?.table.filteredColumns?.[groupParameter?.id]
                                ?.length < groupParameter?.parameters?.length
                            }
                            onChange={({ target: { checked } }) =>
                              columnGroupOptionFilterOnChange(
                                checked,
                                groupParameter,
                              )
                            }
                            checked={
                              groupParameter?.parameters?.length ===
                              input.table.filteredColumns?.[groupParameter?.id]
                                ?.length
                            }
                            style={{ width: "100%" }}
                          >
                            <b>{groupParameter.title}</b>
                          </Checkbox>
                          <Checkbox.Group
                            value={
                              input?.table.filteredColumns?.[
                                groupParameter?.id
                              ] || []
                            }
                            options={groupParameter?.parameters?.map(
                              (parameter) => ({
                                value: parameter.id,
                                label: parameter.title,
                              }),
                            )}
                            style={{
                              flexDirection: "column",
                              padding: "0 0 0 1.7em",
                            }}
                            onChange={(parameterId) =>
                              columnOptionFilterOnChange(
                                groupParameter?.id,
                                parameterId,
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </Form>
                </>
              )}
            </Sider>
          </Layout>
        )}
      </Content>
    </>
  );
}
