import { Button, Divider, Form, Input, Select, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  fetchUserPreference,
  persistUserPreference,
} from "src/pages/profile/components/users/api/userPreference";
import { countries } from "src/components/country";
import { languages } from "src/components/lang";
import { supportedLanguages } from "src/i18n";
import { passwordForgot } from "src/pages/auth/login/api/auth";
import { error, success } from "src/store/notifications";
import { usePreferences } from "src/store/userPreferences";

export const AccountPreferences = () => {
  const [t] = useTranslation("dashboard");
  const { language, setLanguage } = usePreferences();

  const [input, setInput] = useState({
    profile: {
      name: null,
      country: null,
      organization: null,
      language: language,
    },
    countries: countries.map((item) => ({
      value: item.code,
      label: `${item.flag} ${item.name} ${
        item.name === item.country ? "" : `(${item.country})`
      }`,
    })),
    languages: languages
      .filter((language) => supportedLanguages.includes(language.code))
      .map((item) => ({
        value: item.code,
        label: `${item.flag} ${item.language}`,
      })),
    status: {
      changePassword: false,
    },
    countdown: 60,
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        profile: (await fetchUserPreference()) || {},
      });
    };

    init();
  }, []);

  const nameOnChange = ({ target: { value } }) => {
    setInput({ ...input, profile: { ...input.profile, name: value } });
  };

  const preferencesSaveOnClick = async () => {
    try {
      await persistUserPreference({
        name: input.profile.name,
        country: input.profile.country,
        language: input.profile.language,
      });
      success(t("profile.preferencesPersisted"));
    } catch (e) {
      console.log("Persist", e.message);
      error(t("profile.preferencesPersisted"));
    }
  };

  const countryOnSelect = (value) => {
    setInput({ ...input, profile: { ...input.profile, country: value } });
  };

  const languageOnSelect = (value) => {
    setInput({ ...input, profile: { ...input.profile, language: value } });
    setLanguage(value);
  };

  const passwordResetOnClick = async () => {
    if (input.status.changePassword) {
      return;
    }

    setInput({
      ...input,
      status: { ...input.status, changePassword: true },
    });

    await passwordForgot(input.profile.email);

    const interval = setInterval(() => {
      setInput((prev) => {
        if (prev.countdown <= 1) {
          clearInterval(interval);
          return {
            ...prev,
            status: { ...prev.status, changePassword: false },
            countdown: 60,
          };
        }
        return { ...prev, countdown: prev.countdown - 1 };
      });
    }, 1000);
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("profile.name")}>
        <Input value={input.profile.name} onChange={nameOnChange} />
      </Form.Item>
      <Form.Item label={t("profile.country")}>
        <Select
          value={input.profile.country}
          onChange={countryOnSelect}
          options={input.countries}
          showSearch
          optionFilterProp="label"
          virtual
        />
      </Form.Item>
      <Form.Item label={t("profile.language")}>
        <Select
          value={input.profile.language}
          onChange={languageOnSelect}
          options={input.languages}
          showSearch
          virtual
        />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          <Button
            onClick={preferencesSaveOnClick}
            type="primary"
            icon={<SaveOutlined />}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
      <Divider />
      {/*{input.profile.organization && (*/}
      {/*  <Form.Item label={t("organization.organization")}>*/}
      {/*    <Typography.Text>{input.profile.organization}</Typography.Text>*/}
      {/*  </Form.Item>*/}
      {/*)}*/}
      <Form.Item label={t("authentication.email")}>
        <Typography.Text>
          {t("profile.userAccountLinked")}: <b>{input.profile.email}</b>
        </Typography.Text>
        <Button
          onClick={passwordResetOnClick}
          disabled={input.countdown !== 60 || input.status.changePassword}
          color="primary"
          variant="link"
        >
          {input.status.changePassword
            ? `${t("authentication.emailSent")} (${input.countdown})`
            : t("authentication.changePassword")}
        </Button>
      </Form.Item>
    </Form>
  );
};
