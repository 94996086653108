import { App } from "antd";

let message = null;
let notification = null;
let modal = null;

export default function Notifications() {
  const app = App.useApp();
  message = app.message;
  modal = app.modal;
  notification = app.notification;
  return null;
}

export const success = (content) => {
  message.success(content);
};

export const error = (content) => {
  message.error(content);
};
