import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteNavigation,
  fetchNavigation,
  fetchNavigations,
  persistNavigation,
} from "./api/navigation";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { error, success } from "src/store/notifications";
import { usePreferences } from "src/store/userPreferences";

export default function NavigationSection() {
  const [t] = useTranslation("catman");
  const { lang } = useParams();
  const { language } = usePreferences();

  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    id: null,
    existingId: null,
    status: "new",
    options: [],
    translation: {
      language: language,
      name: {},
    },
  });

  useEffect(() => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        language: language,
      },
    });
  }, [language]);

  useEffect(() => {
    const fetchAllNavigations = async () => {
      setInput({
        ...input,
        options: (await fetchNavigations(lang)) || [],
      });
    };

    fetchAllNavigations();
  }, []);

  function findParentByChildId(items, childId, parent = null) {
    return items.reduce(
      (found, item) =>
        found ||
        (item.value === childId
          ? parent
          : item.children
            ? findParentByChildId(item.children, childId, item)
            : null),
      null,
    );
  }

  const navigationStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      id: null,
      existingId: null,
      translation: {
        ...input.translation,
        name: {},
      },
    });
  };

  const existingNavigationOnChange = async (value) => {
    const navigation = (await fetchNavigation(value?.split("|")[1])) || {};

    setInput({
      ...input,
      existingId: value,
      id: findParentByChildId(input.options, value)?.value,
      translation: {
        ...input.translation,
        name: navigation?.translation || {},
      },
    });
  };

  const currentNavigationOnChange = (value) => {
    setInput({
      ...input,
      id: value,
    });
  };

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const navigationTranslationOnChange = (value) => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        language: value,
      },
    });
  };

  const navigationNameTranslationOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      translation: {
        ...input.translation,
        name: {
          ...input.translation.name,
          [input.translation.language]: value,
        },
      },
    });
  };

  const deleteNavigationOnClick = async () => {
    try {
      await deleteNavigation(input.existingId?.split("|")[1]);
      success(t("navigation.delete"));
      setInput({
        ...input,
        existingId: null,
        options: (await fetchNavigations(lang)) || [],
        translation: {
          ...input.translation,
          name: {},
        },
      });
    } catch (e) {
      error(t("navigation.delete"));
      console.log("Delete", e.message);
    }
  };

  const saveNavigationOnClick = async () => {
    try {
      const [_, existingId] = input.existingId?.split("|") || [];
      const [__, id] = input.id?.split("|") || [];

      await persistNavigation({
        parent_id: id || 0,
        id: existingId,
        translation: {
          ...input.translation.name,
        },
      });
      success(t("navigation.persist"));
      setInput({
        ...input,
        existingId: `${id || 0}|${existingId}`,
        options: (await fetchNavigations(lang)) || [],
      });
    } catch (e) {
      error(t("navigation.persist"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={navigationStatusOnChange}
          />
          {input.status === "existing" && (
            <TreeSelect
              treeData={input.options}
              value={input.existingId}
              onChange={existingNavigationOnChange}
              filterTreeNode={filterTreeNode}
              placeholder={t("navigation.navigation")}
              showSearch
              allowClear
              treeDefaultExpandAll
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("navigation.parent")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.options}
            value={input.id}
            onChange={currentNavigationOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("navigation.parent")}
            showSearch
            allowClear
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("navigation.navigation")}>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            value={input.translation.language}
            options={[
              {
                value: "de",
                label: "🇩🇪",
              },
              {
                value: "en",
                label: "🇺🇸",
              },
              {
                value: "pl",
                label: "🇵🇱",
              },
              {
                value: "ua",
                label: "🇺🇦",
              },
            ]}
            onChange={navigationTranslationOnChange}
            style={{ width: "auto" }}
            virtual
          />
          <Input
            value={input.translation.name[input.translation.language]}
            onChange={navigationNameTranslationOnChange}
            placeholder={t("navigation.navigation")}
            style={{ width: "100%" }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.existingId && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteNavigationOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveNavigationOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
}
