import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Segmented,
  Select,
  Space,
  Tooltip,
  TreeSelect,
} from "antd";
import { CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  fetchAdminEntities,
  fetchAdminPermissions,
  fetchAdminRole,
  fetchAdminRoles,
  persistPermission,
  removePermission,
} from "src/common/api/admin/role";
import { usePreferences } from "src/store/userPreferences";
import { error, success } from "src/store/notifications";

export const AdminPermissions = () => {
  const [t] = useTranslation("dashboard");

  const { language } = usePreferences();

  const [input, setInput] = useState({
    status: "new",
    role: {
      id: null,
      language: language,
      translations: {},
      entityId: null,
      permissionIds: null,
    },
    roles: [],
    entities: [],
    permissions: [],
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        roles: (await fetchAdminRoles())?.roles?.map((role) => ({
          value: role.id,
          label: role.name,
        })),
        entities: (await fetchAdminEntities())?.entities?.map((entity) => ({
          value: entity.id,
          label: entity.name,
        })),
        permissions: (await fetchAdminPermissions())?.permissions?.map(
          (permission) => ({
            value: permission.id,
            label: permission.name,
            children: permission.children?.map((child) => ({
              value: child.id,
              label: child.name,
            })),
          }),
        ),
      });
    };

    init();
  }, []);

  const renderTreeNodes = (data) =>
    data?.map((item) => (
      <TreeSelect.TreeNode
        value={item.value}
        title={<Tooltip title={item.title}>{item.title}</Tooltip>}
      >
        {item.children ? renderTreeNodes(item.children) : null}
      </TreeSelect.TreeNode>
    ));

  const roleStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      role: {
        id: null,
        language: language,
        translations: {},
        entityId: null,
        permissionIds: null,
      },
    });
  };

  const roleTranslationOnChange = (value) => {
    setInput({
      ...input,
      role: {
        ...input.role,
        language: value,
      },
    });
  };

  const roleOnInput = ({ target: { value } }) => {
    setInput({
      ...input,
      role: {
        ...input.role,
        translations: {
          ...input.role.translations,
          [input.role.language]: value,
        },
      },
    });
  };

  const entityOnClear = () => {
    setInput({ ...input, role: { ...input.role, entityId: null } });
  };

  const entityOnSelect = (value) => {
    setInput({
      ...input,
      role: {
        ...input.role,
        entityId: value,
      },
    });
  };

  const roleOnSelect = async (value) => {
    const { role, errors } = await fetchAdminRole(value);

    if (errors) {
      error(t("organization.rolePersisted"));
      return;
    }

    setInput({
      ...input,
      role: {
        ...input.role,
        id: role?.id,
        translations: role?.translations,
        entityId: role?.entityId,
        permissionIds: role?.permissionIds,
      },
    });
  };

  const permissionOnClear = () => {
    setInput({
      ...input,
      role: {
        ...input.role,
        id: null,
      },
    });
  };

  const permissionOnChange = (value) => {
    setInput({
      ...input,
      role: {
        ...input.role,
        permissionIds: value,
      },
    });
  };

  const saveOnClick = async () => {
    try {
      const { id, entityId, translations, permissionIds } = input.role;

      const payload = {
        entityId: entityId,
        translations: translations,
        permissionIds: permissionIds,
      };

      const response = await persistPermission(id, payload);

      if (response?.errors) {
        error(t("organization.rolePersisted"));
        return;
      }

      // if (input.role?.id) {
      //   setInput({
      //     ...input,
      //     existingId: response.id,
      //     roles: input.roles.map((role) => {
      //       if (role.value === input.existingId) {
      //         return {
      //           ...role,
      //           label: input.role.translations[input.role.language],
      //         };
      //       }
      //       return role;
      //     }),
      //   });
      // } else {
      //   setInput({
      //     ...input,
      //     existingId: response.id,
      //     groups: [...input.groups, payload],
      //   });
      // }

      success(t("organization.rolePersisted"));
    } catch (e) {
      console.log("Persist", e.message);
      error(t("organization.rolePersisted"));
    }
  };

  const deleteOnClick = async () => {
    try {
      const response = await removePermission(input.role?.id);

      if (response?.errors) {
        error(t("organization.roleRemoved"));
        return;
      }

      setInput({
        ...input,
        roles: input.roles.filter((g) => g.value !== input.role?.id),
        status: "new",
        role: {
          id: null,
          language: language,
          translations: {},
          entityId: null,
          permissionIds: null,
        },
      });

      success(t("organization.roleRemoved"));
    } catch (e) {
      console.log("Delete", e.message);
      error(t("organization.roleRemoved"));
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={roleStatusOnChange}
          />
          {input.status === "existing" && (
            <Select
              value={input.role?.id}
              options={input.roles}
              showSearch
              placeholder={t("account.role")}
              onChange={roleOnSelect}
              onSearch={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              allowClear={{
                clearIcon: <CloseOutlined onClick={permissionOnClear} />,
              }}
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("account.role")}>
        <Space.Compact style={{ width: "100%" }}>
          <Select
            value={input.role.language || language}
            options={[
              {
                value: "de",
                label: "🇩🇪",
              },
              {
                value: "en",
                label: "🇺🇸",
              },
              {
                value: "pl",
                label: "🇵🇱",
              },
              {
                value: "ua",
                label: "🇺🇦",
              },
            ]}
            onChange={roleTranslationOnChange}
            style={{ width: "auto" }}
            virtual
          />
          <Input
            value={input.role.translations?.[input.role.language]}
            onChange={roleOnInput}
            placeholder={t("account.role")}
            style={{ width: "100%" }}
          />
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("organization.entity")}>
        <Select
          value={input.role?.entityId}
          options={input.entities}
          showSearch
          placeholder={t("organization.entity")}
          onChange={entityOnSelect}
          onSearch={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          allowClear={{
            clearIcon: <CloseOutlined onClick={entityOnClear} />,
          }}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item label={t("organization.permissions")}>
        <TreeSelect
          placeholder={t("organization.permissions")}
          value={input.role?.permissionIds}
          treeData={input.permissions}
          onChange={permissionOnChange}
          filterTreeNode={(inputValue, node) =>
            node?.title?.toLowerCase().indexOf(inputValue?.toLowerCase()) >= 0
          }
          showCheckedStrategy="SHOW_PARENT"
          showSearch
          treeCheckable
          allowClear
        >
          {renderTreeNodes(input.permissions)}
        </TreeSelect>
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.role?.id && (
            <Button icon={<DeleteOutlined />} danger onClick={deleteOnClick}>
              {t("action.delete")}
            </Button>
          )}
          <Button type="primary" icon={<SaveOutlined />} onClick={saveOnClick}>
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
