import { combineReducers } from "src/store/reducers/utils/combineReducers";
import {
  preferencesInitialState,
  preferencesReducer,
} from "src/store/reducers/preferences";
import {
  dashboardInitialState,
  dashboardReducer,
} from "src/store/reducers/dashboard";
import {
  authorizationInitialState,
  authorizationReducer,
} from "src/store/reducers/authorization";

export const rootReducer = combineReducers({
  authorization: authorizationReducer,
  preferences: preferencesReducer,
  dashboard: dashboardReducer,
});

export const rootInitialState = {
  authorization: authorizationInitialState,
  preferences: preferencesInitialState,
  dashboard: dashboardInitialState,
};
