import React, { createContext, useContext, useReducer } from "react";
import { rootInitialState, rootReducer } from "src/store/reducers/root";

const StoreContext = createContext({
  state: {},
  dispatch: () => {},
});

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    rootReducer,
    null,
    () => rootInitialState,
  );

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
