import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  deleteTrademark,
  fetchTrademarks,
  persistTrademark,
} from "src/pages/catman/components/products/components/trademark/api/trademark";
import { error, success } from "src/store/notifications";

export const Trademark = () => {
  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    status: "new",
    brandId: null,
    brands: [],
    trademarkId: null,
    trademarks: [],
    trademarkName: "",
  });

  const findParent = (array, id) => {
    for (const parent of array) {
      if (parent.children?.some((child) => child.value === id)) {
        return parent;
      }
      const found = findParent(parent?.children || [], id);
      if (found) return found;
    }
    return null;
  };

  useEffect(() => {
    const init = async () => {
      const trademarks = (await fetchTrademarks()) || [];

      setInput({
        ...input,
        trademarks: trademarks.map((brand) => ({
          value: brand.value,
          title: brand.title,
          children: brand.trademarks?.map((trademark) => ({
            value: `${brand.value}|${trademark.value}`,
            title: trademark.title,
          })),
        })),
        brands: trademarks.map((brand) => ({
          value: brand.value,
          label: brand.title,
        })),
      });
    };

    init();
  }, []);

  const trademarkStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      brandId: null,
      trademarkId: null,
      trademarkName: "",
    });
  };

  const existingTrademarkOnChange = (value) => {
    const parent = findParent(input?.trademarks || [], value);
    setInput({
      ...input,
      brandId: parent?.value,
      trademarkId: value,
      trademarkName: parent?.children?.find(
        (trademark) => trademark.value === value,
      )?.title,
    });
  };

  const trademarkNameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      trademarkName: value,
    });
  };

  const deleteTrademarkOnClick = async () => {
    if (input.trademarkId !== null) {
      try {
        await deleteTrademark(input.trademarkId.split("|")[1]);
        success(t("trademark.delete"));
      } catch (e) {
        error(t("trademark.delete"));
        console.log("Delete", e.message);
      }
    }

    console.log(
      input.trademarks?.filter((brand) =>
        brand?.children?.some((child) => {
          console.log(input.trademarkId, child.value);
          return child.value !== input.trademarkId;
        }),
      ),
    );

    setInput({
      ...input,
      trademarks: input.trademarks?.map((brand) => ({
        ...brand,
        children: brand.children?.filter(
          (child) => child.value !== input.trademarkId,
        ),
      })),
      brandId: null,
      trademarkId: null,
      trademarkName: "",
    });
  };

  const saveTrademarkOnClick = async () => {
    try {
      const request = {
        brand_id: input.brandId,
        id: input.status === "new" ? null : input.trademarkId.split("|")[1],
        name: input.trademarkName,
      };

      const response = await persistTrademark(request);
      const trademarkId = `${response?.brand_id}|${response?.id}`;
      success(t("trademark.persist"));
      setInput({
        ...input,
        trademarks:
          input.status === "new"
            ? input.trademarks.map((brand) =>
                brand.value === input.brandId
                  ? {
                      ...brand,
                      children: [
                        ...brand.children,
                        {
                          value: trademarkId,
                          title: response?.name,
                        },
                      ],
                    }
                  : brand,
              )
            : input.trademarks.map((brand) =>
                brand.value === input.brandId
                  ? {
                      ...brand,
                      children: brand.children.map((child) =>
                        child.value === trademarkId
                          ? {
                              ...child,
                              ...{
                                title: response?.name,
                              },
                            }
                          : child,
                      ),
                    }
                  : brand,
              ),
        trademarkId: trademarkId,
        trademarkName: response?.name,
      });
    } catch (e) {
      error(t("trademark.persist"));
      console.log("Persist", e.message);
    }
  };

  const brandOnChange = (value) => {
    setInput({
      ...input,
      brandId: value,
    });
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={trademarkStatusOnChange}
          />
          {/*{input.status === "new" && (*/}
          {/*  <TreeSelect*/}
          {/*    placeholder={t("brand.brandName")}*/}
          {/*    value={input.brandId}*/}
          {/*    onChange={newTrademarkOnChange}*/}
          {/*    treeData={input.trademarks.map((brand) => ({*/}
          {/*      ...brand,*/}
          {/*      children: brand?.children?.map((trademark) => ({*/}
          {/*        ...trademark,*/}
          {/*        disabled: true,*/}
          {/*      })),*/}
          {/*    }))}*/}
          {/*    showSearch*/}
          {/*    allowClear*/}
          {/*    treeDefaultExpandAll*/}
          {/*    style={{ width: "100%" }}*/}
          {/*  />*/}
          {/*)}*/}
          {input.status === "existing" && (
            <TreeSelect
              placeholder={t("trademark.existingTrademark")}
              value={input.trademarkId}
              onChange={existingTrademarkOnChange}
              treeData={input.trademarks.map((brand) => ({
                ...brand,
                disabled: true,
              }))}
              showSearch
              allowClear
              treeDefaultExpandAll
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("brand.brand")}>
        <Select
          placeholder={t("brand.brand")}
          value={input.brandId}
          onChange={brandOnChange}
          options={input.brands}
          showSearch
          optionFilterProp="label"
          virtual={true}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item label={t("trademark.trademarkName")}>
        <Input value={input.trademarkName} onChange={trademarkNameOnChange} />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.brandId && input.status === "existing" && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteTrademarkOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveTrademarkOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
