import { Breadcrumb, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Brand } from "src/pages/catman/components/products/components/brand/Brand";
import { Product } from "src/pages/catman/components/products/components/product/Product";
import { Trademark } from "src/pages/catman/components/products/components/trademark/Trademark";
import { ProductPrice } from "src/pages/catman/components/products/components/productPrice/ProductPrice";
import { usePreferences } from "src/store/userPreferences";

export default function Products() {
  const { language } = usePreferences();

  const params = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    subGroup: params.subGroup || "brand",
  });

  useEffect(() => {
    const subGroup = params.subGroup || "brand";
    setInput({ ...input, subGroup: subGroup });
  }, []);

  const navigationOnChange = (key) => {
    setInput({ ...input, subGroup: key });
    navigate(`/${language}/catman/${params.section}/${params.group}/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog.catalog"),
          },
          {
            title: t("product.product"),
          },
        ]}
      />
      <Tabs
        activeKey={input.subGroup}
        onChange={navigationOnChange}
        items={[
          {
            key: "brand",
            label: t("brand.brand"),
            children: <Brand />,
          },
          {
            key: "trademark",
            label: t("trademark.trademark"),
            children: <Trademark />,
          },
          {
            key: "product",
            label: t("product.product"),
            children: <Product />,
          },
          {
            key: "price",
            label: t("product.price"),
            children: <ProductPrice />,
          },
          // {
          //   key: "bulk-add",
          //   label: t("bulkAdd"),
          //   children: <BulkAdd />,
          // },
          // {
          //   key: "product-management",
          //   label: t("productManagement"),
          //   children: <ProductManagement />,
          // },
        ]}
      />
    </>
  );
}
