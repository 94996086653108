export const countries = [
  {
    code: "af",
    flag: "🇦🇫",
    country: "Afghanistan",
    name: "افغانستان",
  },
  {
    code: "al",
    flag: "🇦🇱",
    country: "Albania",
    name: "Shqipëri",
  },
  {
    code: "dz",
    flag: "🇩🇿",
    country: "Algeria",
    name: "الجزائر",
  },
  {
    code: "as",
    flag: "🇦🇸",
    country: "American Samoa",
    name: "Amerika Sāmoa",
  },
  {
    code: "ad",
    flag: "🇦🇩",
    country: "Andorra",
    name: "Andorra",
  },
  {
    code: "ao",
    flag: "🇦🇴",
    country: "Angola",
    name: "Angola",
  },
  {
    code: "ai",
    flag: "🇦🇮",
    country: "Anguilla",
    name: "Anguilla",
  },
  {
    code: "aq",
    flag: "🇦🇶",
    country: "Antarctica",
    name: "Antarctica",
  },
  {
    code: "ag",
    flag: "🇦🇬",
    country: "Antigua and Barbuda",
    name: "Antigua and Barbuda",
  },
  {
    code: "ar",
    flag: "🇦🇷",
    country: "Argentina",
    name: "Argentina",
  },
  {
    code: "am",
    flag: "🇦🇲",
    country: "Armenia",
    name: "Հայաստան",
  },
  {
    code: "aw",
    flag: "🇦🇼",
    country: "Aruba",
    name: "Aruba",
  },
  {
    code: "au",
    flag: "🇦🇺",
    country: "Australia",
    name: "Australia",
  },
  {
    code: "at",
    flag: "🇦🇹",
    country: "Austria",
    name: "Österreich",
  },
  {
    code: "az",
    flag: "🇦🇿",
    country: "Azerbaijan",
    name: "Azərbaycan",
  },
  {
    code: "bs",
    flag: "🇧🇸",
    country: "Bahamas",
    name: "Bahamas",
  },
  {
    code: "bh",
    flag: "🇧🇭",
    country: "Bahrain",
    name: "البحرين",
  },
  {
    code: "bd",
    flag: "🇧🇩",
    country: "Bangladesh",
    name: "বাংলাদেশ",
  },
  {
    code: "bb",
    flag: "🇧🇧",
    country: "Barbados",
    name: "Barbados",
  },
  {
    code: "by",
    flag: "🇧🇾",
    country: "Belarus",
    name: "Беларусь",
  },
  {
    code: "be",
    flag: "🇧🇪",
    country: "Belgium",
    name: "België",
  },
  {
    code: "bz",
    flag: "🇧🇿",
    country: "Belize",
    name: "Belize",
  },
  {
    code: "bj",
    flag: "🇧🇯",
    country: "Benin",
    name: "Bénin",
  },
  {
    code: "bm",
    flag: "🇧🇲",
    country: "Bermuda",
    name: "Bermuda",
  },
  {
    code: "bt",
    flag: "🇧🇹",
    country: "Bhutan",
    name: "འབྲུག",
  },
  {
    code: "bo",
    flag: "🇧🇴",
    country: "Bolivia",
    name: "Bolivia",
  },
  {
    code: "bq",
    flag: "🇧🇶",
    country: "Bonaire, Sint Eustatius and Saba",
    name: "Bonaire, Sint Eustatius en Saba",
  },
  {
    code: "ba",
    flag: "🇧🇦",
    country: "Bosnia and Herzegovina",
    name: "Bosna i Hercegovina",
  },
  {
    code: "bw",
    flag: "🇧🇼",
    country: "Botswana",
    name: "Botswana",
  },
  {
    code: "bv",
    flag: "🇧🇻",
    country: "Bouvet Island",
    name: "Bouvetøya",
  },
  {
    code: "br",
    flag: "🇧🇷",
    country: "Brazil",
    name: "Brasil",
  },
  {
    code: "io",
    flag: "🇮🇴",
    country: "British Indian Ocean Territory",
    name: "British Indian Ocean Territory",
  },
  {
    code: "bn",
    flag: "🇧🇳",
    country: "Brunei Darussalam",
    name: "بروني دارالسلام",
  },
  {
    code: "bg",
    flag: "🇧🇬",
    country: "Bulgaria",
    name: "България",
  },
  {
    code: "bf",
    flag: "🇧🇫",
    country: "Burkina Faso",
    name: "Burkina Faso",
  },
  {
    code: "bi",
    flag: "🇧🇮",
    country: "Burundi",
    name: "Burundi",
  },
  {
    code: "cv",
    flag: "🇨🇻",
    country: "Cabo Verde",
    name: "Cabo Verde",
  },
  {
    code: "kh",
    flag: "🇰🇭",
    country: "Cambodia",
    name: "កម្ពុជា",
  },
  {
    code: "cm",
    flag: "🇨🇲",
    country: "Cameroon",
    name: "Cameroun",
  },
  {
    code: "ca",
    flag: "🇨🇦",
    country: "Canada",
    name: "Canada",
  },
  {
    code: "ky",
    flag: "🇰🇾",
    country: "Cayman Islands",
    name: "Cayman Islands",
  },
  {
    code: "cf",
    flag: "🇨🇫",
    country: "Central African Republic",
    name: "République centrafricaine",
  },
  {
    code: "td",
    flag: "🇹🇩",
    country: "Chad",
    name: "Tchad",
  },
  {
    code: "cl",
    flag: "🇨🇱",
    country: "Chile",
    name: "Chile",
  },
  {
    code: "cn",
    flag: "🇨🇳",
    country: "China",
    name: "中国",
  },
  {
    code: "cx",
    flag: "🇨🇽",
    country: "Christmas Island",
    name: "Christmas Island",
  },
  {
    code: "cc",
    flag: "🇨🇨",
    country: "Cocos (Keeling) Islands",
    name: "Cocos (Keeling) Islands",
  },
  {
    code: "co",
    flag: "🇨🇴",
    country: "Colombia",
    name: "Colombia",
  },
  {
    code: "km",
    flag: "🇰🇲",
    country: "Comoros",
    name: "جزر القمر",
  },
  {
    code: "cg",
    flag: "🇨🇬",
    country: "Congo",
    name: "Congo",
  },
  {
    code: "cd",
    flag: "🇨🇩",
    country: "Congo, Democratic Republic of the",
    name: "République démocratique du Congo",
  },
  {
    code: "ck",
    flag: "🇨🇰",
    country: "Cook Islands",
    name: "Cook Islands",
  },
  {
    code: "cr",
    flag: "🇨🇷",
    country: "Costa Rica",
    name: "Costa Rica",
  },
  {
    code: "hr",
    flag: "🇭🇷",
    country: "Croatia",
    name: "Hrvatska",
  },
  {
    code: "cu",
    flag: "🇨🇺",
    country: "Cuba",
    name: "Cuba",
  },
  {
    code: "cw",
    flag: "🇨🇼",
    country: "Curaçao",
    name: "Curaçao",
  },
  {
    code: "cy",
    flag: "🇨🇾",
    country: "Cyprus",
    name: "Κύπρος",
  },
  {
    code: "cz",
    flag: "🇨🇿",
    country: "Czech Republic",
    name: "Česká republika",
  },
  {
    code: "dk",
    flag: "🇩🇰",
    country: "Denmark",
    name: "Danmark",
  },
  {
    code: "dj",
    flag: "🇩🇯",
    country: "Djibouti",
    name: "جيبوتي",
  },
  {
    code: "dm",
    flag: "🇩🇲",
    country: "Dominica",
    name: "Dominica",
  },
  {
    code: "do",
    flag: "🇩🇴",
    country: "Dominican Republic",
    name: "República Dominicana",
  },
  {
    code: "ec",
    flag: "🇪🇨",
    country: "Ecuador",
    name: "Ecuador",
  },
  {
    code: "eg",
    flag: "🇪🇬",
    country: "Egypt",
    name: "مصر",
  },
  {
    code: "kn",
    flag: "🇰🇳",
    country: "Saint Kitts and Nevis",
    name: "Saint Kitts and Nevis",
  },
  {
    code: "lc",
    flag: "🇱🇨",
    country: "Saint Lucia",
    name: "Saint Lucia",
  },
  {
    code: "mf",
    flag: "🇲🇫",
    country: "Saint Martin",
    name: "Saint-Martin",
  },
  {
    code: "pm",
    flag: "🇵🇲",
    country: "Saint Pierre and Miquelon",
    name: "Saint-Pierre-et-Miquelon",
  },
  {
    code: "vc",
    flag: "🇻🇨",
    country: "Saint Vincent and the Grenadines",
    name: "Saint Vincent and the Grenadines",
  },
  {
    code: "ws",
    flag: "🇼🇸",
    country: "Samoa",
    name: "Sāmoa",
  },
  {
    code: "sm",
    flag: "🇸🇲",
    country: "San Marino",
    name: "San Marino",
  },
  {
    code: "st",
    flag: "🇸🇹",
    country: "Sao Tome and Principe",
    name: "São Tomé e Príncipe",
  },
  {
    code: "sa",
    flag: "🇸🇦",
    country: "Saudi Arabia",
    name: "المملكة العربية السعودية",
  },
  {
    code: "sn",
    flag: "🇸🇳",
    country: "Senegal",
    name: "Sénégal",
  },
  {
    code: "rs",
    flag: "🇷🇸",
    country: "Serbia",
    name: "Србија",
  },
  {
    code: "sc",
    flag: "🇸🇨",
    country: "Seychelles",
    name: "Seychelles",
  },
  {
    code: "sl",
    flag: "🇸🇱",
    country: "Sierra Leone",
    name: "Sierra Leone",
  },
  {
    code: "sg",
    flag: "🇸🇬",
    country: "Singapore",
    name: "Singapore",
  },
  {
    code: "sx",
    flag: "🇸🇽",
    country: "Sint Maarten",
    name: "Sint Maarten",
  },
  {
    code: "sk",
    flag: "🇸🇰",
    country: "Slovakia",
    name: "Slovensko",
  },
  {
    code: "si",
    flag: "🇸🇮",
    country: "Slovenia",
    name: "Slovenija",
  },
  {
    code: "sb",
    flag: "🇸🇧",
    country: "Solomon Islands",
    name: "Solomon Islands",
  },
  {
    code: "so",
    flag: "🇸🇴",
    country: "Somalia",
    name: "Soomaaliya",
  },
  {
    code: "za",
    flag: "🇿🇦",
    country: "South Africa",
    name: "South Africa",
  },
  {
    code: "gs",
    flag: "🇬🇸",
    country: "South Georgia and the South Sandwich Islands",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "ss",
    flag: "🇸🇸",
    country: "South Sudan",
    name: "South Sudan",
  },
  {
    code: "es",
    flag: "🇪🇸",
    country: "Spain",
    name: "España",
  },
  {
    code: "lk",
    flag: "🇱🇰",
    country: "Sri Lanka",
    name: "ශ්‍රී ලංකා",
  },
  {
    code: "pl",
    flag: "🇵🇱",
    country: "Poland",
    name: "Polska",
  },
  {
    code: "sd",
    flag: "🇸🇩",
    country: "Sudan",
    name: "السودان",
  },
  {
    code: "sr",
    flag: "🇸🇷",
    country: "Suriname",
    name: "Suriname",
  },
  {
    code: "sj",
    flag: "🇸🇯",
    country: "Svalbard and Jan Mayen",
    name: "Svalbard og Jan Mayen",
  },
  {
    code: "se",
    flag: "🇸🇪",
    country: "Sweden",
    name: "Sverige",
  },
  {
    code: "ch",
    flag: "🇨🇭",
    country: "Switzerland",
    name: "Schweiz",
  },
  {
    code: "sy",
    flag: "🇸🇾",
    country: "Syrian Arab Republic",
    name: "سوريا",
  },
  {
    code: "tw",
    flag: "🇹🇼",
    country: "Taiwan",
    name: "臺灣",
  },
  {
    code: "tj",
    flag: "🇹🇯",
    country: "Tajikistan",
    name: "Тоҷикистон",
  },
  {
    code: "tz",
    flag: "🇹🇿",
    country: "Tanzania",
    name: "Tanzania",
  },
  {
    code: "th",
    flag: "🇹🇭",
    country: "Thailand",
    name: "ประเทศไทย",
  },
  {
    code: "tl",
    flag: "🇹🇱",
    country: "Timor-Leste",
    name: "Timor-Leste",
  },
  {
    code: "tg",
    flag: "🇹🇬",
    country: "Togo",
    name: "Togo",
  },
  {
    code: "tk",
    flag: "🇹🇰",
    country: "Tokelau",
    name: "Tokelau",
  },
  {
    code: "to",
    flag: "🇹🇴",
    country: "Tonga",
    name: "Tonga",
  },
  {
    code: "tt",
    flag: "🇹🇹",
    country: "Trinidad and Tobago",
    name: "Trinidad and Tobago",
  },
  {
    code: "tn",
    flag: "🇹🇳",
    country: "Tunisia",
    name: "تونس",
  },
  {
    code: "tr",
    flag: "🇹🇷",
    country: "Turkey",
    name: "Türkiye",
  },
  {
    code: "tm",
    flag: "🇹🇲",
    country: "Turkmenistan",
    name: "Türkmenistan",
  },
  {
    code: "tc",
    flag: "🇹🇨",
    country: "Turks and Caicos Islands",
    name: "Turks and Caicos Islands",
  },
  {
    code: "tv",
    flag: "🇹🇻",
    country: "Tuvalu",
    name: "Tuvalu",
  },
  {
    code: "ug",
    flag: "🇺🇬",
    country: "Uganda",
    name: "Uganda",
  },
  {
    code: "ua",
    flag: "🇺🇦",
    country: "Ukraine",
    name: "Україна",
  },
  {
    code: "ae",
    flag: "🇦🇪",
    country: "United Arab Emirates",
    name: "الإمارات العربية المتحدة",
  },
  {
    code: "gb",
    flag: "🇬🇧",
    country: "United Kingdom",
    name: "United Kingdom",
  },
  {
    code: "us",
    flag: "🇺🇸",
    country: "United States of America",
    name: "United States of America",
  },
  {
    code: "uy",
    flag: "🇺🇾",
    country: "Uruguay",
    name: "Uruguay",
  },
  {
    code: "uz",
    flag: "🇺🇿",
    country: "Uzbekistan",
    name: "Oʻzbekiston",
  },
  {
    code: "vu",
    flag: "🇻🇺",
    country: "Vanuatu",
    name: "Vanuatu",
  },
  {
    code: "ve",
    flag: "🇻🇪",
    country: "Venezuela",
    name: "Venezuela",
  },
  {
    code: "vn",
    flag: "🇻🇳",
    country: "Viet Nam",
    name: "Việt Nam",
  },
  {
    code: "wf",
    flag: "🇼🇫",
    country: "Wallis and Futuna",
    name: "Wallis-et-Futuna",
  },
  {
    code: "eh",
    flag: "🇪🇭",
    country: "Western Sahara",
    name: "الصحراء الغربية",
  },
  {
    code: "ye",
    flag: "🇾🇪",
    country: "Yemen",
    name: "اليمن",
  },
  {
    code: "zm",
    flag: "🇿🇲",
    country: "Zambia",
    name: "Zambia",
  },
  {
    code: "zw",
    flag: "🇿🇼",
    country: "Zimbabwe",
    name: "Zimbabwe",
  },
];
