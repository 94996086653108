import { deleteAPI, getAPI, postAPI } from "src/common/api/api";

const PATH = "catman/product/brand";

export const fetchBrands = async (lang) => getAPI(PATH);

export const fetchBrand = async (id) => getAPI(`${PATH}/${id}`);

export const persistBrand = async (body) => postAPI(PATH, body);

export const deleteBrand = async (id) => deleteAPI(`${PATH}/${id}`);
