import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "src/pages/profile/components/users/components/Profile.css";
import { AccountPreferences } from "src/pages/profile/components/users/components/account/AccountPreferences";
import { useNavigate, useParams } from "react-router-dom";
import { usePreferences } from "src/store/userPreferences";

export const Account = () => {
  const profile = "profile";

  const [t] = useTranslation("dashboard");
  const navigate = useNavigate();
  const params = useParams();

  const { language } = usePreferences();

  const [input, setInput] = useState({
    prefer: params.prefer || profile,
  });

  useEffect(() => {
    setInput({
      ...input,
      prefer: params.prefer || profile,
    });

    if (![profile].includes(params.prefer)) {
      navigate(
        `/${language}/user/${params.preferences}/${params.preference}/${profile}`,
      );
    }
  }, [params.prefer]);

  const preferenceOnChange = (key) => {
    setInput({ ...input, prefer: key });
    navigate(
      `/${language}/user/${params.preferences}/${params.preference}/${key}`,
      { replace: true },
    );
  };

  return (
    <Tabs
      activeKey={input.preference}
      onChange={preferenceOnChange}
      items={[
        {
          key: profile,
          label: t("profile.profile"),
          children: <AccountPreferences />,
        },
      ]}
    />
  );
};
