import Layout, { Content } from "antd/lib/layout/layout";
import { Button, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  ShoppingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Category from "src/pages/catman/components/category/Category";
import Products from "src/pages/catman/components/products/Products";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePreferences } from "src/store/userPreferences";
import { useAuthorization } from "src/store/authorization";

export default function Catman() {
  const category = "category";
  const product = "product";

  const [t] = useTranslation("catman");
  const params = useParams();
  const navigate = useNavigate();
  const { language } = usePreferences();
  const { isAdmin } = useAuthorization();

  const [input, setInput] = useState({
    collapsed: true,
    group: params.group || "category",
    navigationKeys: [],
  });

  const items = [
    {
      key: "catalog",
      icon: <ShopOutlined />,
      label: t("catalog.catalog"),
      children: [
        {
          key: category,
          icon: <TagOutlined />,
          label: t("category.category"),
        },
        {
          key: product,
          icon: <ShoppingOutlined />,
          label: t("product.product"),
        },
      ],
    },
    // {
    //   key: "settings",
    //   icon: <SettingOutlined />,
    //   label: t("preferences.settings"),
    // },
  ];

  useEffect(() => {
    if (!isAdmin) {
      navigate(`/${language || "en"}`);
    }

    const group = params.group || "category";
    const section = params.section || "catalog";

    setInput({
      ...input,
      group: group,
      navigationKeys: [section, group],
    });
  }, [params.group, params.section]);

  const menuOnSelect = ({ key }) => {
    setInput({ ...input, group: key });
    navigate(`/${language}/catman/${input.navigationKeys[0]}/${key}`);
  };

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const onNavigationKeysChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => input.navigationKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const levelKeys = getLevelKeys(items);

      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setInput({
        ...input,
        navigationKeys: openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      });
    } else {
      setInput({
        ...input,
        navigationKeys: openKeys,
      });
    }
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsedWidth={0}
        collapsible
        theme="light"
        collapsed={input.collapsed}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[input.group]}
          openKeys={input.navigationKeys}
          onSelect={menuOnSelect}
          onOpenChange={onNavigationKeysChange}
          items={items}
        />
      </Sider>
      <Layout style={{ padding: "0 50px", background: "#fff" }}>
        <Button
          type="text"
          icon={input.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setInput({ ...input, collapsed: !input.collapsed })}
          style={{
            justifyContent: "start",
          }}
        />
        <Content>
          {input.group === category && <Category />}
          {input.group === product && <Products />}
          {/*{input.navigation === "settings" && <Settings />}*/}
        </Content>
      </Layout>
    </Layout>
  );
}
