import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import {
  deleteBrand,
  fetchBrands,
  persistBrand,
} from "src/pages/catman/components/products/components/brand/api/brand";
import { error, success } from "src/store/notifications";

export const Brand = () => {
  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    status: "new",
    brands: [],
    existingId: null,
    brandName: "",
  });

  useEffect(() => {
    const getBrands = async () => {
      setInput({
        ...input,
        brands: (await fetchBrands()) || [],
      });
    };

    getBrands();
  }, []);

  const brandStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      existingId: null,
      brandName: "",
    });
  };

  const existingBrandOnChange = (value) => {
    setInput({
      ...input,
      existingId: value,
      brandName: input.brands.find((o) => o.value === value)?.label,
    });
  };

  const brandNameOnChange = ({ target: { value } }) => {
    setInput({
      ...input,
      brandName: value,
    });
  };

  const deleteBrandOnClick = async () => {
    if (input.existingId !== null) {
      try {
        await deleteBrand(input.existingId);
        success(t("brand.delete"));
      } catch (e) {
        error(t("brand.delete"));
        console.log("Delete", e.message);
      }
    }

    setInput({
      ...input,
      brands: input.brands.filter((item) => item.value !== input.existingId),
      existingId: null,
      brandName: "",
    });
  };

  const saveBrandOnClick = async () => {
    try {
      const request = {
        id: input.status === "new" ? null : input.existingId,
        name: input.brandName,
      };
      const response = await persistBrand(request);
      success(t("brand.persist"));
      setInput({
        ...input,
        brands:
          input.status === "new"
            ? [...input.brands, { value: response?.id, label: response?.name }]
            : input.brands.map((item) =>
                item.value === input.existingId
                  ? { ...item, value: response?.id, label: response?.name }
                  : item,
              ),
        existingId: response?.id,
      });
    } catch (e) {
      error(t("brand.persist"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={brandStatusOnChange}
          />
          {input.status === "existing" && (
            <Select
              placeholder={t("brand.existingBrand")}
              value={input.existingId}
              onChange={existingBrandOnChange}
              options={input.brands}
              showSearch
              optionFilterProp="label"
              virtual={true}
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("brand.brandName")}>
        <Input
          value={input.brandName}
          placeholder={t("brand.brandName")}
          onChange={brandNameOnChange}
        />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.existingId && input.status === "existing" && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteBrandOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveBrandOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
