import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, notification, Select, Space } from "antd";
import { fetchProducts } from "src/pages/catman/components/products/components/productPrice/api/product";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { error, success } from "src/store/notifications";
import {
  fetchProductPrice,
  persistProductPrice,
  removeProductPrice,
} from "src/pages/catman/components/products/components/productPrice/api/productPrice";

export const ProductPrice = () => {
  const { t } = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    products: [],
    productId: null,
    price: null,
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        products: ((await fetchProducts()) || []).map((product) => ({
          value: product.productId,
          label: `${product.ean} (${product.mpn})`,
        })),
      });
    };

    init();
  }, []);

  const productOnChange = async (value) => {
    setInput({
      ...input,
      productId: value,
      price: (await fetchProductPrice(value))?.product_price,
    });
  };

  const priceOnChange = (value) => {
    setInput({
      ...input,
      price: value,
    });
  };

  const priceDeleteOnClick = async () => {
    try {
      await removeProductPrice(input.productId, input.price);

      setInput({
        ...input,
        price: null,
      });

      success(t("product.productPriceRemoved"));
    } catch (e) {
      error(t("product.productPriceRemoved"));
      console.log("Persist", e.message);
    }
  };

  const priceSaveOnClick = async () => {
    try {
      const response = await persistProductPrice(input.productId, input.price);

      setInput({
        ...input,
        price: response?.product_price,
      });

      success(t("product.productPricePersisted"));
    } catch (e) {
      error(t("product.productPricePersisted"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("product.product")}>
        <Select
          placeholder={t("product.product")}
          value={input.productId}
          onChange={productOnChange}
          options={input.products}
          showSearch
          optionFilterProp="label"
          allowClear
          virtual={true}
          style={{ width: "100%" }}
        />
      </Form.Item>
      {input.productId && (
        <>
          <Form.Item label={t("product.price")}>
            <InputNumber
              placeholder={t("product.price")}
              value={input.price}
              onChange={priceOnChange}
            />
          </Form.Item>
          <Form.Item>
            <Space.Compact className="form_buttons-action">
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={priceDeleteOnClick}
              >
                {t("action.delete")}
              </Button>
              {input.price && (
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  onClick={priceSaveOnClick}
                >
                  {t("action.save")}
                </Button>
              )}
            </Space.Compact>
          </Form.Item>
        </>
      )}
    </Form>
  );
};
