import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  TreeSelect,
} from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCategories } from "src/pages/catman/components/category/components/groupParameters/api/category";
import {
  fetchGroupParameters,
  persistGroupParameters,
} from "./api/groupParameter";
import { error, success } from "src/store/notifications";
import { usePreferences } from "src/store/userPreferences";

export const GroupParameters = () => {
  const { language } = usePreferences();
  const { lang } = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    id: null,
    options: [],
    language: language,
    groupParameters: [],
  });

  useEffect(() => {
    setInput({
      ...input,
      language: language,
    });
  }, [language]);

  useEffect(() => {
    const fetchAllCategories = async () => {
      setInput({
        ...input,
        options: (await fetchCategories(lang)) || [],
      });
    };
    fetchAllCategories();
  }, []);

  const traverseTree = (data, source) => {
    return data.map((item) => ({
      ...item,
      disabled: item.type === source,
      children: item?.children ? traverseTree(item?.children, source) : [],
    }));
  };

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const existingCategoryOnChange = async (value) => {
    if (value === null || value === undefined) {
      setInput({
        ...input,
        id: null,
        groupParameters: [],
      });

      return;
    }

    const groupParameters =
      (await fetchGroupParameters(value.split("|")[1])) || [];

    let i = 0;

    setInput({
      ...input,
      id: value,
      groupParameters: groupParameters.map((groupParameter) => ({
        ...groupParameter,
        language: language,
        key: i++,
      })),
    });
  };

  const addNewGroupParameterOnClick = async () => {
    setInput({
      ...input,
      groupParameters: [
        ...input.groupParameters,
        {
          key:
            Math.max(...input.groupParameters.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          language: language,
          translation: {},
        },
      ],
    });
  };

  const translationOnChange = (key, item) => {
    setInput({
      ...input,
      groupParameters: input.groupParameters.map((groupParameter) => ({
        ...groupParameter,
        language: groupParameter.key === key ? item : groupParameter.language,
      })),
    });
  };

  const translationNameInputOnChange = (key, value) => {
    setInput({
      ...input,
      groupParameters: input.groupParameters.map((groupParameter) => {
        if (groupParameter.key === key) {
          return {
            ...groupParameter,
            translation: {
              ...groupParameter.translation,
              [groupParameter.language]: value,
            },
          };
        }

        return groupParameter;
      }),
    });
  };

  const deleteGroupOnClick = async (key) => {
    setInput({
      ...input,
      groupParameters: input?.groupParameters?.filter((p) => p.key !== key),
    });
  };

  const groupParametersSaveOnClick = async () => {
    const groupParameters = (input.groupParameters || [])
      .map((groupParameter) => ({
        group_parameter_id: groupParameter.id,
        order_index: groupParameter.order_index || null,
        translation: groupParameter.translation,
      }))
      .filter((groupParameter) =>
        Object.values(groupParameter?.translation || {}).some(
          (param) => param?.trim() !== "",
        ),
      );

    try {
      const response =
        (await persistGroupParameters({
          category_id: input.id.split("|")[1],
          group_parameters: groupParameters,
        })) || [];

      setInput({
        ...input,
        groupParameters: response.map((groupParameter, i) => ({
          ...groupParameter,
          language: language,
          key: i + 1,
        })),
      });

      success(t("groupParameter.persist"));
    } catch (e) {
      error(t("groupParameter.persist"));
      console.log("Save", e.message);
    }
  };

  const groupParametersDeleteOnClick = async () => {
    try {
      await persistGroupParameters({
        category_id: input.id.split("|")[1],
        group_parameters: [],
      });
      success(t("groupParameter.delete"));
    } catch (e) {
      error(t("groupParameter.delete"));
      console.log("Delete", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("category.parentCategory")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={traverseTree(input.options, "NAVIGATION")}
            value={input.id}
            onChange={existingCategoryOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("category.parentCategory")}
            showSearch
            allowClear
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.groupParameters?.map((item) => (
        <div key={item.key}>
          <Divider />
          <Form.Item label={t("groupParameter.groupParametersName")}>
            <Space.Compact style={{ width: "100%" }}>
              <Select
                value={item.language}
                options={[
                  {
                    value: "de",
                    label: "🇩🇪",
                  },
                  {
                    value: "en",
                    label: "🇺🇸",
                  },
                  {
                    value: "pl",
                    label: "🇵🇱",
                  },
                  {
                    value: "ua",
                    label: "🇺🇦",
                  },
                ]}
                onChange={(value) => translationOnChange(item.key, value)}
                style={{ width: "auto" }}
                virtual
              />
              <Input
                placeholder={t("groupParameter.groupParametersName")}
                value={item.translation[item.language || language]}
                onChange={({ target: { value } }) =>
                  translationNameInputOnChange(item.key, value)
                }
                style={{ width: "100%" }}
              />
              <Button
                onClick={() => deleteGroupOnClick(item.key)}
                icon={<DeleteOutlined />}
                danger
              ></Button>
            </Space.Compact>
          </Form.Item>
        </div>
      ))}
      <Divider />
      <Form.Item>
        <Space.Compact>
          {input.id && (
            <Button
              onClick={addNewGroupParameterOnClick}
              icon={<PlusOutlined />}
            >
              {t("settings.addNew")}
            </Button>
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.id && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={groupParametersDeleteOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={groupParametersSaveOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
