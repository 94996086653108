import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchParameters } from "src/pages/catman/components/category/components/paremeterOption/api/parameter";
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  TreeSelect,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  fetchParameterOption,
  persistParameterOption,
} from "src/pages/catman/components/category/components/paremeterOption/api/parameterOption";
import { error, success } from "src/store/notifications";
import { usePreferences } from "src/store/userPreferences";

export const ParameterOption = () => {
  const { language } = usePreferences();
  const { lang } = useParams();
  const [t] = useTranslation("catman");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    parameters: [],
    parameterId: null,
    parameterOptions: [],
  });

  useEffect(() => {
    const initInput = async () => {
      setInput({
        ...input,
        parameters: ((await fetchParameters(lang)) || []).map((item) => ({
          ...item,
          disabled: true,
          children: item.children.filter((child) => child.type === "TEXT"),
        })),
      });
    };

    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const groupParameterOnChange = async (value) => {
    if (!value) {
      setInput({
        ...input,
        parameterId: null,
        parameterOptions: [],
      });

      return;
    }

    const parameterOptions =
      (await fetchParameterOption(value?.split("|")[1])) || [];

    setInput({
      ...input,
      parameterId: value,
      parameterOptions: parameterOptions.map((parameterOption, i) => ({
        ...parameterOption,
        key: i + 1,
        language: language,
      })),
    });
  };

  const translationOnChange = (key, item) => {
    setInput({
      ...input,
      parameterOptions: input.parameterOptions.map((parameterOption) => ({
        ...parameterOption,
        language: parameterOption.key === key ? item : parameterOption.language,
      })),
    });
  };

  const translationNameInputOnChange = (key, value) => {
    setInput({
      ...input,
      parameterOptions: input.parameterOptions.map((parameterOptions) => {
        if (parameterOptions.key === key) {
          return {
            ...parameterOptions,
            translation: {
              ...parameterOptions.translation,
              [parameterOptions.language]: value,
            },
          };
        }

        return parameterOptions;
      }),
    });
  };

  const addNewParameterOptionOnClick = () => {
    setInput({
      ...input,
      parameterOptions: [
        ...input.parameterOptions,
        {
          key:
            Math.max(...input.parameterOptions.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          language: language,
          translation: {},
        },
      ],
    });
  };

  const deleteParameterOptionOnClick = async (key) => {
    setInput({
      ...input,
      parameterOptions: input?.parameterOptions?.filter((p) => p.key !== key),
    });
  };

  const deleteParameterOptionsOnClick = async () => {
    try {
      const parameterOption = await persistParameterOption(
        input.parameterId?.split("|")[1],
      );

      setInput({
        ...input,
        parameterOptions: parameterOption.map((parameterOption, i) => ({
          ...parameterOption,
          key: i + 1,
          language: language,
        })),
      });

      success(t("parameterOption.delete"));
    } catch (e) {
      error(t("parameterOption.delete"));
      console.log("Delete", e.message);
    }
  };

  const saveParameterOptionsOnClick = async () => {
    try {
      const parameterOptions = input.parameterOptions
        .map((parameterOption) => ({
          parameter_option_id: parameterOption?.value?.split("|")[1] || null,
          order_index: parameterOption.order_index || null,
          translation: parameterOption.translation,
        }))
        .filter((parameterOption) =>
          Object.values(parameterOption?.translation || {}).some(
            (param) => param?.trim() !== "",
          ),
        );

      const response = await persistParameterOption({
        parameter_id: input.parameterId?.split("|")[1],
        parameter_options: parameterOptions,
      });

      setInput({
        ...input,
        parameterOptions: response.map((parameterOption, i) => ({
          ...parameterOption,
          key: i + 1,
          language: language,
        })),
      });

      success(t("notifications.success"), t("parameterOption.persist"));
    } catch (e) {
      error(t("notifications.error"), t("parameterOption.persist"));
      console.log("Persist", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("parameter.parentParameters")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.parameters}
            value={input.parameterId}
            onChange={groupParameterOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("parameter.parentParameters")}
            showSearch
            allowClear={{
              clearIcon: <CloseOutlined onClick={groupParameterOnChange} />,
            }}
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.parameterOptions?.map((item) => (
        <div key={item.key}>
          <Divider />
          <Form.Item label={t("parameter.parameterName")}>
            <Space.Compact style={{ width: "100%" }}>
              <Select
                value={item.language}
                options={[
                  {
                    value: "de",
                    label: "🇩🇪",
                  },
                  {
                    value: "en",
                    label: "🇺🇸",
                  },
                  {
                    value: "pl",
                    label: "🇵🇱",
                  },
                  {
                    value: "ua",
                    label: "🇺🇦",
                  },
                ]}
                onChange={(value) => translationOnChange(item.key, value)}
                style={{ width: "auto" }}
                virtual
              />
              <Input
                placeholder={t("parameter.parameterName")}
                value={item.translation?.[item?.language || language]}
                onChange={({ target: { value } }) =>
                  translationNameInputOnChange(item.key, value)
                }
                style={{ width: "100%" }}
              />
              <Button
                onClick={() => deleteParameterOptionOnClick(item.key)}
                icon={<DeleteOutlined />}
                danger
              ></Button>
            </Space.Compact>
          </Form.Item>
        </div>
      ))}
      <Divider />
      <Form.Item>
        <Space.Compact>
          {input.parameterId && (
            <Button
              onClick={addNewParameterOptionOnClick}
              icon={<PlusOutlined />}
            >
              {t("settings.addNew")}
            </Button>
          )}
        </Space.Compact>
      </Form.Item>
      {input.parameterId && (
        <Form.Item>
          <Space.Compact className="form_buttons-action">
            <Button
              onClick={deleteParameterOptionsOnClick}
              icon={<DeleteOutlined />}
              danger
            >
              {t("action.delete")}
            </Button>
            <Button
              onClick={saveParameterOptionsOnClick}
              type="primary"
              icon={<SaveOutlined />}
            >
              {t("action.save")}
            </Button>
          </Space.Compact>
        </Form.Item>
      )}
    </Form>
  );
};
