export const persistCookie = (name, value, period) => {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "string" &&
      value instanceof String &&
      value?.trim() === "")
  ) {
    return;
  }

  document.cookie = `${name}=${value || ""}; expires=${period}; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  return (
    (document?.cookie?.split(";") || []).reduce((latest, c) => {
      c = c.trim();
      if (!c.startsWith(nameEQ) || c.length <= nameEQ.length) return latest;

      const value = c.slice(nameEQ.length);
      const expires =
        c.match(/expires=([^;]+)/)?.[1] || ""
          ? new Date(c.match(/expires=([^;]+)/)[1]).getTime()
          : Infinity;

      return expires > Date.now() && (!latest || expires > latest.expires)
        ? { value, expires }
        : latest;
    }, null)?.value || null
  );
};

export const removeCookie = (name) => {
  document?.cookie?.split(";").forEach((c) => {
    if (c.trim().startsWith(`${name}=`)) {
      [
        "",
        "/",
        ...(document.cookie
          .match(/path=([^;]+)/g)
          ?.map((p) => p.split("=")[1]) || []),
      ].forEach((path) => {
        document.cookie = `${name}=;expires=-99999999;path=${path || "/"}`;
      });
    }
  });
};

export const hasCookieRemoved = (name) => {
  return getCookie(name) === null;
};
