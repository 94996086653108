import {
  Button,
  Cascader,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
  TreeSelect,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { fetchUnits } from "src/pages/catman/components/category/components/parameter/api/units";
import { useParams } from "react-router-dom";
import { fetchParameter, persistParameter } from "./api/parameter";
import { fetchGroupParameters } from "./api/groupParameter";
import { error, success } from "src/store/notifications";
import { usePreferences } from "src/store/userPreferences";

export default function Parameter() {
  const TEXT = "TEXT";
  const NUMBER = "NUMBER";
  const LOGICAL = "LOGICAL";

  const { language } = usePreferences();
  const { lang } = useParams();
  const [t] = useTranslation("catman");

  const [input, setInput] = useState({
    groupParameters: [],
    groupParameterId: null,
    units: [],
    addNew: TEXT,
    parameters: [],
  });

  useEffect(() => {
    const initInput = async () => {
      setInput({
        ...input,
        groupParameters: ((await fetchGroupParameters(lang)) || []).map(
          (groupParameter) => ({
            ...groupParameter,
            disabled: true,
          }),
        ),
        units: (await fetchUnits(lang)) || [],
      });
    };

    initInput();
  }, []);

  const filterTreeNode = (input, node) => {
    return node?.title?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  };

  const parseDecimal = (value, decimalPlaceValue) =>
    value
      .replace(
        new RegExp(`^(\\d+,?\\d{0,${decimalPlaceValue}})\\d*$`, "g"),
        "$1",
      )
      .replace(/^0+(?=\d)/, "")
      .replace(new RegExp(`(\\.\\d{0,${decimalPlaceValue}}).*$`, "g"), "$1")
      .replace(/^(\d+)$/, `$1.${"0".repeat(decimalPlaceValue)}`);

  const groupParameterOnChange = async (value) => {
    if (!value) {
      setInput({
        ...input,
        groupParameterId: null,
        parameters: [],
      });
      return;
    }

    const parameters = (await fetchParameter(value?.split("|")[1])) || [];

    setInput({
      ...input,
      groupParameterId: value,
      parameters: parameters.map((parameter, i) => ({
        ...parameter,
        unit_id: [
          findParentValue(parameter.unit_id, input.units),
          parameter.unit_id,
        ],
        language: language,
        key: i + 1,
      })),
    });
  };

  const findParentValue = (childId, array) => {
    const stack = [...array];

    while (stack.length > 0) {
      const item = stack.pop();

      if (item.children) {
        for (const child of item.children) {
          if (child.value === childId) {
            return item.value;
          }
        }
        stack.push(...item.children);
      }
    }

    return null;
  };

  const newParameterTypeOnSelect = ({ item: { props } }) => {
    setInput({
      ...input,
      addNew: props.value,
    });
  };

  const addNewParameterOnClick = () => {
    setInput({
      ...input,
      parameters: [
        ...input.parameters,
        {
          key: Math.max(...input.parameters.map((item) => item.key), 0) + 1,
          id: null,
          order_index: null,
          type: input.addNew,
          language: language,
          translation: {},
        },
      ],
    });
  };

  const parameterLanguageOnSelect = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key ? { ...parameter, language: value } : parameter,
      ),
    });
  };

  const parameterInputOnChange = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key
          ? {
              ...parameter,
              translation: {
                ...parameter.translation,
                [parameter.language]: value,
              },
            }
          : parameter,
      ),
    });
  };

  const parameterUnitOnChange = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key ? { ...parameter, unit_id: value } : parameter,
      ),
    });
  };

  const parameterMinValueOnChange = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key ? { ...parameter, min_value: value } : parameter,
      ),
    });
  };

  const parameterMaxValueOnChange = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key ? { ...parameter, max_value: value } : parameter,
      ),
    });
  };

  const decimalPlaceValueOnChange = (key, value) => {
    setInput({
      ...input,
      parameters: input.parameters.map((parameter) =>
        parameter.key === key
          ? {
              ...parameter,
              decimal_place_value: value,
              min_value: parseDecimal(parameter.min_value?.toString(), value),
              max_value: parseDecimal(parameter.max_value?.toString(), value),
            }
          : parameter,
      ),
    });
  };

  const parameterOptionDeleteOnClick = (key) => {
    setInput({
      ...input,
      parameters: input.parameters.filter((p) => p.key !== key),
    });
  };

  const parameterSaveOnClick = async () => {
    try {
      const parameters = input.parameters
        .map((parameter) => ({
          parameter_id: parameter.value?.split("|")[1] || null,
          type: parameter.type,
          order_index: parameter.order_index || null,
          min_value: parameter.min_value || null,
          max_value: parameter.max_value || null,
          decimal_place_value: parameter.decimal_place_value || null,
          unit_id:
            Array.isArray(parameter.unit_id) && parameter.unit_id?.length > 0
              ? parameter.unit_id?.at(-1) || null
              : null,
          translation: parameter.translation,
        }))
        .filter((parameter) =>
          Object.values(parameter?.translation || {}).some(
            (value) => value?.trim() !== "",
          ),
        );

      const data = {
        group_parameter_id: input.groupParameterId?.split("|")[1],
        parameters,
      };

      const response = (await persistParameter(data)) || [];
      setInput({
        ...input,
        parameters: response.map((parameter, i) => ({
          ...parameter,
          unit_id: [
            findParentValue(parameter.unit_id, input.units),
            parameter.unit_id,
          ],
          language: language,
          key: i + 1,
        })),
      });
      success(t("parameter.persist"));
    } catch (e) {
      error(t("parameter.persist"));
      console.log("Persist", e.message);
    }
  };

  const parameterDeleteOnClick = async () => {
    try {
      await persistParameter({
        group_parameter_id: input.groupParameterId?.split("|")[1],
        parameters: [],
      });
      success(t("parameter.delete"));
    } catch (e) {
      error(t("parameter.delete"));
      console.log("Delete", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item label={t("groupParameter.parentGroupParameters")}>
        <Space.Compact style={{ width: "100%" }}>
          <TreeSelect
            treeData={input.groupParameters}
            value={input.groupParameterId}
            onChange={groupParameterOnChange}
            filterTreeNode={filterTreeNode}
            placeholder={t("groupParameter.parentGroupParameters")}
            showSearch
            allowClear={{
              clearIcon: <CloseOutlined onClick={groupParameterOnChange} />,
            }}
            treeDefaultExpandAll
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
          />
        </Space.Compact>
      </Form.Item>
      {input.parameters?.map((item) => (
        <div key={item.key}>
          {item.type === TEXT && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterOptionDeleteOnClick(item.key)}
                  />
                  {t("parameterType.text")}
                </Space>
              </Divider>
              <Form.Item label={t("parameter.parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={item.language}
                    onChange={(value) =>
                      parameterLanguageOnSelect(item.key, value)
                    }
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    onChange={({ target: { value } }) =>
                      parameterInputOnChange(item.key, value)
                    }
                    placeholder={t("parameter.parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
            </>
          )}
          {item.type === NUMBER && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterOptionDeleteOnClick(item.key)}
                  />
                  {t("parameterType.number")}
                </Space>
              </Divider>
              <Form.Item label={t("parameter.parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={item.language}
                    onChange={(value) =>
                      parameterLanguageOnSelect(item.key, value)
                    }
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    onChange={({ target: { value } }) =>
                      parameterInputOnChange(item.key, value)
                    }
                    placeholder={t("parameter.parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
              <Form.Item label={t("category.measurementUnit")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Cascader
                    expandTrigger="hover"
                    value={item.unit_id}
                    options={input.units}
                    onChange={(value) => parameterUnitOnChange(item.key, value)}
                    placeholder={t("category.measurementUnit")}
                    showSearch={{
                      filter: (inputValue, path) =>
                        path.some(
                          (option) =>
                            option.label
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) > -1,
                        ),
                    }}
                  />
                </Space.Compact>
              </Form.Item>
              <Form.Item label={t("parameterType.possibleValueForParameter")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Tooltip title={t("parameterType.minValue")}>
                    <InputNumber
                      value={item.min_value}
                      onChange={(value) =>
                        parameterMinValueOnChange(item.key, value)
                      }
                      parser={(value) =>
                        parseDecimal(value, item.decimal_place_value)
                      }
                      keyboard
                      changeOnWheel
                    />
                  </Tooltip>
                  <Tooltip title={t("parameterType.maxValue")}>
                    <InputNumber
                      value={item.max_value}
                      onChange={(value) =>
                        parameterMaxValueOnChange(item.key, value)
                      }
                      parser={(value) =>
                        parseDecimal(value, item.decimal_place_value)
                      }
                      keyboard
                      changeOnWheel
                    />
                  </Tooltip>
                  <Tooltip title={t("parameterType.decimalPlaceValue")}>
                    <Select
                      value={item.decimal_place_value}
                      options={[
                        {
                          value: "0",
                          label: "0",
                        },
                        {
                          value: "1",
                          label: "1",
                        },
                        {
                          value: "2",
                          label: "2",
                        },
                        {
                          value: "3",
                          label: "3",
                        },
                        {
                          value: "4",
                          label: "4",
                        },
                        {
                          value: "5",
                          label: "5",
                        },
                        {
                          value: "6",
                          label: "6",
                        },
                        {
                          value: "7",
                          label: "7",
                        },
                      ]}
                      onChange={(value) =>
                        decimalPlaceValueOnChange(item.key, value)
                      }
                      style={{ maxWidth: "100px" }}
                    />
                  </Tooltip>
                </Space.Compact>
              </Form.Item>
            </>
          )}
          {item.type === LOGICAL && (
            <>
              <Divider orientation="left" plain>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => parameterOptionDeleteOnClick(item.key)}
                  />
                  {t("parameterType.logical")}
                </Space>
              </Divider>
              <Form.Item label={t("parameter.parameterName")}>
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    value={item.language}
                    onChange={(value) =>
                      parameterLanguageOnSelect(item.key, value)
                    }
                    options={[
                      {
                        value: "de",
                        label: "🇩🇪",
                      },
                      {
                        value: "en",
                        label: "🇺🇸",
                      },
                      {
                        value: "pl",
                        label: "🇵🇱",
                      },
                      {
                        value: "ua",
                        label: "🇺🇦",
                      },
                    ]}
                    style={{ width: "auto" }}
                    virtual
                  />
                  <Input
                    value={item.translation[item.language]}
                    onChange={({ target: { value } }) =>
                      parameterInputOnChange(item.key, value)
                    }
                    placeholder={t("parameter.parameterName")}
                    style={{ width: "100%" }}
                  />
                </Space.Compact>
              </Form.Item>
            </>
          )}
        </div>
      ))}
      <Divider />
      {input.groupParameterId !== null && (
        <>
          <Form.Item>
            <Space.Compact>
              <Dropdown.Button
                menu={{
                  items: [
                    {
                      value: TEXT,
                      label: t("parameterType.text"),
                    },
                    {
                      value: NUMBER,
                      label: t("parameterType.number"),
                    },
                    {
                      value: LOGICAL,
                      label: t("parameterType.logical"),
                    },
                  ],
                  onClick: newParameterTypeOnSelect,
                }}
                onClick={addNewParameterOnClick}
              >
                <PlusOutlined /> {t("settings.addNew")}:{" "}
                {t(`parameterType.${input?.addNew?.toLowerCase()}`)}
              </Dropdown.Button>
            </Space.Compact>
          </Form.Item>
          <Form.Item>
            <Space.Compact className="form_buttons-action">
              <Button
                icon={<DeleteOutlined />}
                danger
                onClick={parameterDeleteOnClick}
              >
                {t("action.delete")}
              </Button>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                onClick={parameterSaveOnClick}
              >
                {t("action.save")}
              </Button>
            </Space.Compact>
          </Form.Item>
        </>
      )}
    </Form>
  );
}
