import { getToken, hasToken } from "src/common/api/authentication";

export const authorizationActions = {
  RESET: "reset",
  ROLES: "roles",
};

const getRoles = () => {
  try {
    return hasToken()
      ? JSON.parse(atob((getToken() || "")?.split(".")[1]))?.scopes || []
      : [];
  } catch (e) {
    return [];
  }
};

export const authorizationInitialState = {
  roles: getRoles(),
};

export const authorizationReducer = (state, action) => {
  switch (action.type) {
    case authorizationActions.ROLES:
      return {
        ...state,
        roles: getRoles(),
      };
    case authorizationActions.RESET:
      return authorizationInitialState;
    default:
      return state;
  }
};
