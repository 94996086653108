import { deleteAPI, getAPI, postAPI } from "src/common/api/api";

const PATH = "admin";

export const fetchAdminEntities = async () => getAPI(`${PATH}/user/entity`);

export const fetchAdminPermissions = async () =>
  getAPI(`${PATH}/user/role/permission`);

export const persistPermission = async (roleId, payload) =>
  postAPI(`${PATH}/user/role/${roleId}/permission`, payload);

export const removePermission = async (payload) =>
  deleteAPI("user/role/permission", payload);

export const fetchAdminRoles = async () => getAPI(`${PATH}/user/role`);

export const fetchAdminRole = async (roleId) =>
  getAPI(`${PATH}/user/role/${roleId}/permission`);

export const persistAdminUserRole = async (userId, roleIds) =>
  postAPI(`${PATH}/user/${userId}/role`, { roleIds });

// export const fetchUserGroupRoles = async () => getAPI("user/role/group");
