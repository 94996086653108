import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Segmented,
  Select,
  Space,
} from "antd";
import { CloseOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { error, success } from "src/store/notifications";
import {
  fetchTeams,
  persisTeam,
  removeTeam,
} from "src/pages/profile/components/users/components/organization/api/team";

export const OrganizationTeams = () => {
  const [t] = useTranslation("dashboard");
  const [api, contextHolder] = notification.useNotification();

  const [input, setInput] = useState({
    status: "new",
    existingId: null,
    team: {
      name: null,
    },
    teams: [],
  });

  useEffect(() => {
    const init = async () => {
      setInput({
        ...input,
        teams: ((await fetchTeams())?.teams || []).map((team) => ({
          value: team.id,
          label: team.name,
        })),
      });
    };

    init();
  }, []);

  const teamStatusOnChange = (value) => {
    setInput({
      ...input,
      status: value,
      existingId: null,
      team: { name: null },
    });
  };

  const teamNameOnSelect = (value) => {
    setInput({
      ...input,
      existingId: value,
      team: { name: input.teams.find((team) => team.value === value)?.label },
    });
  };

  const teamNameOnSearch = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const teamNameOnClear = () => {
    setInput({ ...input, existingId: null, team: { name: null } });
  };

  const teamNameOnChange = ({ target: { value } }) => {
    setInput({ ...input, team: { ...input.team, name: value } });
  };

  const saveTeamOnClick = async () => {
    try {
      await persisTeam(input.existingId, input.team.name);
      success(t("organization.teamPersistedName"));
    } catch (e) {
      error(t("organization.teamPersistedName"));
      console.log("Persist", e.message);
    }
  };

  const deleteTeamOnClick = async () => {
    try {
      if (!input.existingId) {
        return;
      }

      await removeTeam(input.existingId);
      success(t("organization.teamDeleted"));
    } catch (e) {
      error(t("organization.teamDeleted"));
      console.log("Delete", e.message);
    }
  };

  return (
    <Form layout="vertical">
      <Form.Item>
        <Space.Compact style={{ width: "100%" }}>
          <Segmented
            value={input.status}
            options={[
              {
                value: "new",
                label: t("settings.new"),
              },
              {
                value: "existing",
                label: t("settings.existing"),
              },
            ]}
            onChange={teamStatusOnChange}
          />
          {input.status === "existing" && (
            <Select
              value={input.existingId}
              options={input.teams}
              showSearch
              placeholder={t("organization.team")}
              onChange={teamNameOnSelect}
              onSearch={teamNameOnSearch}
              allowClear={{
                clearIcon: <CloseOutlined onClick={teamNameOnClear} />,
              }}
              style={{ width: "100%" }}
            />
          )}
        </Space.Compact>
      </Form.Item>
      <Form.Item label={t("organization.team")}>
        <Input
          value={input.team.name}
          onChange={teamNameOnChange}
          placeholder={t("organization.team")}
        />
      </Form.Item>
      <Form.Item>
        <Space.Compact className="form_buttons-action">
          {input.existingId && input.status === "existing" && (
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={deleteTeamOnClick}
            >
              {t("action.delete")}
            </Button>
          )}
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={saveTeamOnClick}
          >
            {t("action.save")}
          </Button>
        </Space.Compact>
      </Form.Item>
    </Form>
  );
};
