const persistToStorage = (key, value) => localStorage.setItem(key, value);

const getFromStorage = (key) => localStorage.getItem(key);

// const removeFromStorage = (key) => localStorage.removeItem(key);

export const clearStorage = () => {
  localStorage.clear();
};

export const setLanguage = (language) => {
  persistToStorage("language", language);
};

export const getLanguage = () => getFromStorage("language");

// export const persistToken = (token, expires) => {
//   persistToStorage("token", JSON.stringify({ token, expires }));
// };
//
// export const getToken = () => {
//   const { token, expires } = JSON.parse(getFromStorage("token")) || {
//     token: null,
//     expires: null,
//   };
//
//   if (expires < Math.floor(Date.now() / 1000)) {
//     return token;
//   } else {
//     console.log(expires, Date.now());
//     removeFromStorage("token");
//     return null;
//   }
// };
//
// export const hasToken = () => !!getToken();
