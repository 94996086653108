import { Button, Menu } from "antd";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Layout, { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import { Account } from "src/pages/profile/components/users/Account";
import { Admin } from "src/pages/profile/components/users/Admin";
import { OrgAdmin } from "src/pages/profile/components/users/OrgAdmin";
import { useAuthorization } from "src/store/authorization";
import { usePreferences } from "src/store/userPreferences";

export const Profile = () => {
  const preferences = "preferences";
  const account = "account";
  const admin = "admin";
  const organization = "organization";

  const [t] = useTranslation("dashboard");
  const params = useParams();
  const navigate = useNavigate();
  const { language } = usePreferences();
  const { isAdmin, isOrgAdmin } = useAuthorization();

  const [input, setInput] = useState({
    collapsed: true,
    preference: params.preference || account,
    navigationKeys: [],
  });

  const items = [
    {
      key: preferences,
      icon: <SettingOutlined />,
      label: t("account.settings"),
      children: [
        {
          key: account,
          label: t("account.account"),
        },
        isAdmin && {
          key: admin,
          label: t("account.admin"),
        },
        isOrgAdmin && {
          key: organization,
          label: t("organization.organization"),
        },
      ],
    },
  ];

  useEffect(() => {
    const preferences = params.preferences || preferences;
    const preference = params.preference || preference;

    setInput({
      ...input,
      preferences: preferences,
      navigationKeys: [preferences, preference],
    });
  }, [params.preferences, params.preference]);

  const menuOnSelect = ({ key }) => {
    setInput({ ...input, preference: key });
    navigate(`/${language}/user/${params.preferences}/${key}`);
  };

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const onNavigationKeysChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => input.navigationKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const levelKeys = getLevelKeys(items);

      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setInput({
        ...input,
        navigationKeys: openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      });
    } else {
      setInput({
        ...input,
        navigationKeys: openKeys,
      });
    }
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsedWidth={0}
        collapsible
        theme="light"
        collapsed={input.collapsed}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[input.preference]}
          openKeys={input.navigationKeys}
          onSelect={menuOnSelect}
          onOpenChange={onNavigationKeysChange}
          items={items}
        />
      </Sider>
      <Layout style={{ padding: "0 50px", background: "#fff" }}>
        <Button
          type="text"
          icon={input.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setInput({ ...input, collapsed: !input.collapsed })}
          style={{
            justifyContent: "start",
          }}
        />
        <Content>
          {input.preference === account && <Account />}
          {input.preference === admin && <Admin />}
          {input.preference === organization && <OrgAdmin />}
        </Content>
      </Layout>
    </Layout>
  );
};
