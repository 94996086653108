import {
  getCookie,
  hasCookieRemoved,
  persistCookie,
  removeCookie,
} from "src/common/api/cookie";

export const persistToken = (token, expires) => {
  persistCookie("token", token, expires);
};

export const hasToken = () => !hasCookieRemoved("token");

export const getToken = () => getCookie("token");

export const removeToken = () => {
  removeCookie("token");
};
