import { useStore } from "src/store/globalStore";
import { authorizationActions } from "src/store/reducers/authorization";

// web
const ACCOUNT_REGISTERED_VIEW = "account:registered:view";
const ACCOUNT_PRODUCT_CATALOG_VIEW = "account:product_catalog:view";
const ACCOUNT_PRODUCT_FILTER_VIEW = "account:product_filter:view";
const ACCOUNT_CART_VIEW = "account:cart:view";
const ACCOUNT_CART_PERSIST = "account:cart:persist";
const ACCOUNT_CART_REMOVE = "account:cart:remove";
const ACCOUNT_DRAFT_CART_VIEW = "account:draft_cart:view";
const ACCOUNT_DRAFT_CART_PERSIST = "account:draft_cart:persist";
const ACCOUNT_DRAFT_CART_REMOVE = "account:draft_cart:remove";
const ACCOUNT_ADD_PURCHASE_REQUEST_VIEW = "account:add_purchase_request:view";
const ACCOUNT_ADD_PURCHASE_REQUEST_PERSIST =
  "account:add_purchase_request:persist";
const ACCOUNT_ADD_PURCHASE_REQUEST_REMOVE =
  "account:add_purchase_request:remove";
const ACCOUNT_FAVORITE_PRODUCTS_VIEW = "account:favorite_products:view";
const ACCOUNT_FAVORITE_PRODUCTS_PERSIST = "account:favorite_products:persist";
const ACCOUNT_FAVORITE_PRODUCTS_REMOVE = "account:favorite_products:remove";
const ACCOUNT_USER_PREFERENCES_VIEW = "account:user_preferences:view";
const ACCOUNT_USER_PREFERENCES_PERSIST = "account:user_preferences:persist";
const ACCOUNT_USER_PREFERENCES_REMOVE = "account:user_preferences:remove";
const ACCOUNT_ACCOUNT_SETTINGS_VIEW = "account:account_settings:view";
const ACCOUNT_ACCOUNT_SETTINGS_PERSIST = "account:account_settings:persist";
const ACCOUNT_ACCOUNT_SETTINGS_REMOVE = "account:account_settings:remove";

// admin
const ADMIN_ADMIN_VIEW = "admin:admin:view";
const ADMIN_ADMIN_PERSIST = "admin:admin:persist";
const ADMIN_ADMIN_REMOVE = "admin:admin:remove";
const ADMIN_ORG_SUPPLIER_VIEW = "admin:org_supplier:view";
const ADMIN_ORG_SUPPLIER_PERSIST = "admin:org_supplier:persist";
const ADMIN_ORG_SUPPLIER_REMOVE = "admin:org_supplier:remove";

// org
const ORG_ADMIN_VIEW = "org:admin:view";
const ORG_TEAM_MEMBER_PURCHASE_REQUEST_VIEW =
  "org:team_member_purchase_request:view";
const ORG_TEAM_MEMBER_PURCHASE_REQUEST_PERSIST =
  "org:team_member_purchase_request:persist";
const ORG_TEAM_MEMBER_PURCHASE_REQUEST_REMOVE =
  "org:team_member_purchase_request:remove";
const ORG_ORDER_TRACK_PERSONAL_VIEW = "org:order_track_personal:view";
const ORG_ORDER_HISTORY_PERSONAL_VIEW = "org:order_history_personal:view";

export const useAuthorization = () => {
  const {
    state: {
      authorization: { roles },
    },
    dispatch,
  } = useStore();
  const { RESET, ROLES } = authorizationActions;

  return {
    resetAuthorization: () => dispatch({ type: RESET }),
    setRoles: () => dispatch({ type: ROLES }),

    isAuthenticated: roles.includes(ACCOUNT_REGISTERED_VIEW),
    isAdmin: roles.includes(ADMIN_ADMIN_VIEW),
    isOrgAdmin: roles.includes(ORG_ADMIN_VIEW),
  };
};
