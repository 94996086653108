import { createBrowserRouter, Navigate } from "react-router-dom";
import { MainLayout } from "src/layout/MainLayout";
import Home from "src/pages/home/Home";
import { i18nInit } from "src/i18n";
import Catman from "src/pages/catman/Catman";
import { Authentication } from "src/pages/auth/login/Authentication";
import { Profile } from "src/pages/profile/Profile";
import { PasswordReset } from "src/pages/auth/password/PasswordReset";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={`/${i18nInit.resolvedLanguage || "en"}`} replace />,
  },
  {
    path: "/:lang",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "catman",
        children: [
          {
            path: ":section",
            children: [
              {
                path: ":group",
                children: [
                  {
                    path: ":subGroup",
                    element: <Catman />,
                  },
                  {
                    path: "",
                    element: <Catman />,
                  },
                ],
              },
              {
                path: "",
                element: <Navigate to="category" replace />,
              },
            ],
          },
          {
            path: "",
            element: <Navigate to="catalog" replace />,
          },
        ],
      },
      {
        path: "login",
        element: <Authentication />,
      },
      {
        path: "password-reset/:token",
        element: <PasswordReset />,
      },
      {
        path: "user",
        children: [
          {
            path: ":preferences",
            children: [
              {
                path: ":preference",
                children: [
                  {
                    path: ":prefer",
                    element: <Profile />,
                  },
                  {
                    path: "",
                    element: <Navigate to="profile" replace />,
                  },
                ],
              },
              {
                path: "",
                element: <Navigate to="account" replace />,
              },
            ],
          },
          {
            path: "",
            element: <Navigate to="preferences" replace />,
          },
        ],
      },
      {
        path: "*",
        element: <Home />,
      },
    ],
  },
]);

export default AppRouter;
