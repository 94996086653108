import { Breadcrumb, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "src/pages/catman/components/category/Category.css";
import NavigationSection from "src/pages/catman/components/category/components/navigationSection/NavigationSection";
import ProductCategorySection from "src/pages/catman/components/category/components/productCategorySection/ProductCategorySection";
import { GroupParameters } from "src/pages/catman/components/category/components/groupParameters/GroupParameters";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Parameter from "src/pages/catman/components/category/components/parameter/Parameter";
import { ParameterOption } from "src/pages/catman/components/category/components/paremeterOption/ParameterOption";
import { usePreferences } from "src/store/userPreferences";

export default function Category() {
  const [t] = useTranslation("catman");
  const { language } = usePreferences();

  const params = useParams();
  const navigate = useNavigate();

  const [input, setInput] = useState({
    subGroup: params.subGroup || "navigation",
  });

  useEffect(() => {
    const subGroup = params.subGroup || "navigation";
    setInput({ ...input, subGroup: subGroup });
  }, []);

  const navigationOnChange = (key) => {
    setInput({ ...input, subGroup: key });
    navigate(`/${language}/catman/${params.section}/${params.group}/${key}`);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: t("catalog.catalog"),
          },
          {
            title: t("category.category"),
          },
        ]}
      />
      <Tabs
        activeKey={input.subGroup}
        onChange={navigationOnChange}
        items={[
          {
            key: "navigation",
            label: t("navigation.navigation"),
            children: <NavigationSection />,
          },
          {
            key: "product",
            label: t("product.productCategory"),
            children: <ProductCategorySection />,
          },
          {
            key: "group-parameters",
            label: t("groupParameter.groupParameters"),
            children: <GroupParameters />,
          },
          {
            key: "parameters",
            label: t("parameter.parameters"),
            children: <Parameter />,
          },
          {
            key: "parameter-options",
            label: t("parameterOption.parameterOptions"),
            children: <ParameterOption />,
          },
          // {
          //   key: "bulk-add",
          //   label: t("bulk.bulkAdd"),
          //   children: <BulkAdd />,
          // },
          // {
          //   key: "category-management",
          //   label: t("categoryManagement"),
          //   children: <CategoryManagement />,
          // },
        ]}
      />
    </>
  );
}
