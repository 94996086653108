import { postAPI } from "src/common/api/api";

const PATH = "user/password";

export const passwordReset = async (
  token,
  email,
  password,
  password_confirmation,
) =>
  postAPI(`${PATH}/reset`, { token, email, password, password_confirmation });
