import { getLanguage, setLanguage } from "src/common/storage/storage";

export const preferencesActions = {
  RESET: "reset",
  LANGUAGE: "language",
};

export const preferencesInitialState = {
  language: getLanguage() || "en",
};

export const preferencesReducer = (state, action) => {
  switch (action.type) {
    case preferencesActions.LANGUAGE:
      setLanguage(action.language);
      return {
        ...state,
        language: action.language,
      };
    case preferencesActions.RESET:
      return preferencesInitialState;
    default:
      return state;
  }
};
