import { deleteAPI, getAPI, postAPI } from "src/common/api/api";

const PATH = "catman/product";

export const fetchProductPrice = async (productId) =>
  getAPI(`${PATH}/${productId}/price`);

export const persistProductPrice = async (productId, price) =>
  postAPI(`${PATH}/${productId}/price`, { price });

export const removeProductPrice = async (productId) =>
  deleteAPI(`${PATH}/${productId}/price`);
