import { preferencesActions } from "src/store/reducers/preferences";
import { useStore } from "src/store/globalStore";

export const usePreferences = () => {
  const {
    state: { preferences },
    dispatch,
  } = useStore();
  const { RESET, LANGUAGE } = preferencesActions;

  return {
    resetUserPreferences: () => dispatch({ type: RESET }),
    language: preferences.language,
    setLanguage: (language) => dispatch({ type: LANGUAGE, language }),
  };
};
