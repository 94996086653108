import { Tabs } from "antd";
import { OrganizationAccount } from "src/pages/profile/components/users/components/organization/OrganizationAccount";
import { OrganizationTeams } from "src/pages/profile/components/users/components/organization/OrganizationTeams";
import { OrganizationUsers } from "src/pages/profile/components/users/components/organization/OrganizationUsers";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePreferences } from "src/store/userPreferences";

export const OrgAdmin = () => {
  const preferences = "profile";
  const teams = "teams";
  const users = "users";

  const [t] = useTranslation("dashboard");
  const navigate = useNavigate();
  const params = useParams();

  const { language } = usePreferences();

  const [input, setInput] = useState({
    prefer: params.prefer || preferences,
  });

  useEffect(() => {
    setInput({
      ...input,
      prefer: params.prefer || preferences,
    });
  }, [params.prefer]);

  const preferenceOnChange = (key) => {
    setInput({ ...input, prefer: key });
    navigate(
      `/${language}/user/${params.preferences}/${params.preference}/${key}`,
    );
  };

  return (
    <Tabs
      activeKey={input.prefer}
      onChange={preferenceOnChange}
      items={[
        {
          key: preferences,
          label: t("profile.preferences"),
          children: <OrganizationAccount />,
        },
        {
          key: teams,
          label: t("organization.teams"),
          children: <OrganizationTeams />,
        },
        {
          key: users,
          label: t("account.users"),
          children: <OrganizationUsers />,
        },
      ]}
    />
  );
};
