import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AdminPermissions } from "src/pages/profile/components/users/components/admin/AdminPermissions";
import { AdminRole } from "src/pages/profile/components/users/components/admin/AdminRole";
import { usePreferences } from "src/store/userPreferences";

export const Admin = () => {
  const permissions = "permissions";
  const roles = "roles";

  const [t] = useTranslation("dashboard");
  const navigate = useNavigate();
  const params = useParams();

  const { language } = usePreferences();

  const [input, setInput] = useState({
    prefer: params.prefer || permissions,
  });

  useEffect(() => {
    setInput({
      ...input,
      prefer: params.prefer || permissions,
    });

    if (![permissions, roles].includes(params.prefer)) {
      navigate(
        `/${language}/user/${params.preferences}/${params.preference}/${permissions}`,
      );
    }
  }, [params.prefer]);

  const preferenceOnChange = (key) => {
    setInput({ ...input, prefer: key });
    navigate(
      `/${language}/user/${params.preferences}/${params.preference}/${key}`,
    );
  };

  return (
    <Tabs
      activeKey={input.prefer}
      onChange={preferenceOnChange}
      items={[
        {
          key: permissions,
          label: t("organization.permissions"),
          children: <AdminPermissions />,
        },
        {
          key: roles,
          label: t("account.roles"),
          children: <AdminRole />,
        },
      ]}
    />
  );
};
